import * as THREE from 'three'
import { proxy } from 'valtio'

export const collection = [
  {
    "index": 1,
    "prompt": "wall drawing #522 by american conceptual artist Sol LeWitt 1987",
    "id": "65587088416618110577200558799149204570579048033950891222072631902641929605053"
  },
  {
    "index": 2,
    "prompt": "a wall divided vertically into fifteen equal parts each with a different line direction and color and all combinations. red, yellow, blue, black pencil in the style of conceptual artist Sol LeWitt",
    "id": "106499410608219072382961667679945254868578773868663755597299215098026433592832"
  },
  {
    "index": 3,
    "prompt": "wall drawings by american conceptual artist Sol LeWitt with A wall divided into four parts by lines drawn corner to corner. Each section with three different colors made of parallel lines superimposed. Color pencil. , unreal engine 5, octane render, featured on art station, 8k",
    "id": "107472903955590541413922795322646277935252345209084719780183135452899293892429"
  },
  {
    "index": 4,
    "prompt": "wall drawings by american conceptual artist Sol LeWitt with A wall divided into four parts by lines drawn corner to corner. Each section with three different colors made of parallel lines superimposed. Color pencil. , unreal engine 5, octane render, featured on art station, 8k",
    "id": "26789302617252241901404335026941771913670462094217755774875134450001142202688"
  },
  {
    "index": 5,
    "prompt": "wall drawings by american conceptual artist Sol LeWitt with A wall divided into four parts by lines drawn corner to corner. Each section with three different colors made of parallel lines superimposed. Color pencil. , unreal engine 5, octane render, featured on art station, 8k",
    "id": "110319845074460714180604812280863345634885490797997908163411886883706810899986"
  },
  {
    "index": 6,
    "prompt": "within four adjacent squares each 4' by 4' four draftsmen will be employed at 4.00/hour for four hours a day and four four days to draw straight lines 4 inches long using four different colored penciles 9h black, red, yellow and blue. each draftsman will use the same color throughout the four day period working on a different square each day proposal for wall drawing information show Sol LeWitt Sol LeWitt wall drawing instruction",
    "id": "76922268406244443162491937600916652626187109661570036540886003605656371157000"
  },
  {
    "index": 7,
    "prompt": "a 3d render of a large wall in a gallery with lights pointing at it and a wooden floor the wall is yellow with black angular geometric lines in the style of american conceptual artist Sol LeWitt, unreal engine 5, octane, maya, arnold render",
    "id": "31749842000970550012951802346806314427814708417610522844412896670260706934166"
  },
  {
    "index": 8,
    "prompt": "a 3d render of a large wall in a gallery with lights pointing at it and a wooden floor the wall is yellow with black angular geometric lines in the style of american conceptual artist Sol LeWitt, unreal engine 5, octane, maya, arnold render",
    "id": "46943104678566528904948205686497538622565600697846466389408544943306913495660"
  },
  {
    "index": 9,
    "prompt": "a 3d render of a large wall in a gallery with lights pointing at it and a wooden floor the wall is yellow with black angular geometric lines in the style of american conceptual artist Sol LeWitt, unreal engine 5, octane, maya, arnold render",
    "id": "107241494777595580478567168744520167489321789137727755203376374376117469393594"
  },
  {
    "index": 10,
    "prompt": "a 3d render of a large wall in a gallery with lights pointing at it and a wooden floor the wall is yellow with black angular geometric lines in the style of american conceptual artist Sol LeWitt, unreal engine 5, octane, maya, arnold render",
    "id": "85207077665855445384251733299536910217291740787308815283504186119572165712206"
  },
  {
    "index": 11,
    "prompt": "a 3d render of a museum gallery with a wooden floor there is a wall drawing by conceptual artist Sol LeWitt with black background and white geometric angular lines on it, two black benches are in the foreground, ue4, unreal engine 5, unity hdrp, arnold, maya, octane, featured on artstation",
    "id": "87366284340978869736078819768839403633290370939349108274971093469521758927134"
  },
  {
    "index": 12,
    "prompt": "a 3d render of a museum gallery with a wooden floor there is a wall drawing by conceptual artist Sol LeWitt with black background and white geometric angular lines on it, two black benches are in the foreground, ue4, unreal engine 5, unity hdrp, arnold, maya, octane, featured on artstation",
    "id": "40210731682527275254825397658417864870540425341401032178416497818646772611513"
  },
  {
    "index": 13,
    "prompt": "it is the process of conception and realization with which the artist is concerned - Sol LeWitt",
    "id": "59421815010061032350313199949471765923655657526477864183644186325156046757794"
  },
  {
    "index": 14,
    "prompt": "the idea becomes a machine that makes the art by Sol LeWitt",
    "id": "12279372413133270936409726702932841508213971275714796875535647438871784322774"
  },
  {
    "index": 15,
    "prompt": "the idea becomes a machine that makes the art by Sol LeWitt",
    "id": "93185119786301242112459502993629672901011834815565354587326581818430298047423"
  },
  {
    "index": 16,
    "prompt": "the idea becomes a machine that makes the art by Sol LeWitt",
    "id": "93422265780179931629412803939003960823468557855581784894114733047842973006137"
  },
  {
    "index": 17,
    "prompt": "the idea becomes a machine that makes the art by Sol LeWitt",
    "id": "15203811477176287650645516797279068267211460955110516993315062470383447778260"
  },
  {
    "index": 18,
    "prompt": "the idea becomes a machine that makes the art by Sol LeWitt",
    "id": "65890573023638048755627406755799174478540850901845038511142404912715572321655"
  },
  {
    "index": 19,
    "prompt": "in each case, however, the artist would select the basic form and rules would govern the solution of the problem - Sol LeWitt",
    "id": "53543266596301824867443664287043726402534745875205751466986572248809340205546"
  },
  {
    "index": 20,
    "prompt": "art that is meant for the sensation of the eye primarily would be called perceptual rather than conceptual - Sol LeWitt",
    "id": "41707588793713292054111129390849406598639481642741910014180704142343295639494"
  },
  {
    "index": 21,
    "prompt": "art that is meant for the sensation of the eye primarily would be called perceptual rather than conceptual - Sol LeWitt",
    "id": "63789207566888856694951980045089824409086118155037261663403406333882831472153"
  },
  {
    "index": 22,
    "prompt": "Architecture and three-dimensional art are of completely opposite natures. The former is concerned with making an area with a specific function. Architecture, whether it is a work of art or not, must be utilitarian or else fail completely. Art is not utilitarian. - Sol LeWitt",
    "id": "31074989874388097444159018588085815770310393976775336797696822301297217254946"
  },
  {
    "index": 23,
    "prompt": "a realistic detailed photograph of a wall painting in a gallery of four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt",
    "id": "77083772956663770354827456861698925934502255621221549864785536531238312505792"
  },
  {
    "index": 24,
    "prompt": "3 artists drawing on a wall a wall drawing by conceptual artist Sol LeWitt with blue, green, red and purple geometric lines in an art gallery",
    "id": "63619666793327812972313712784138737052510934377064796564657017802976886624111"
  },
  {
    "index": 25,
    "prompt": "atrium with concrete pillars and skylight with colored wall drawings by Sol LeWitt showing that conceptual art is not necessarily logical",
    "id": "48527617638084301656534830668542986363096882916264146194253902674111487193663"
  },
  {
    "index": 26,
    "prompt": "black and white geometric gradient wall drawing made out of tiny squiggle marks in a gallery with wood floors white walls and glowing lights shining on it in the style of conceptual artist Sol LeWitt",
    "id": "82009718514692008846059231023099130991159702944770545565370490144734601381488"
  },
  {
    "index": 27,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left",
    "id": "93605138525731438955293433902028018683389966934127894438161300875102543074272"
  },
  {
    "index": 28,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left",
    "id": "10515543470944560640494328843246930245816235620614988974697638904121255099805"
  },
  {
    "index": 29,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "110547052626593329529033401208342961612771275774306946023937196344059698213604"
  },
  {
    "index": 30,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "67686922397324487562087857988863021418106654494059619761053892507690873810324"
  },
  {
    "index": 31,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "57788340433974216551637598431973356420299187092828213986957801125876337973007"
  },
  {
    "index": 32,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "15586661938294150374278271506990767675755091629199008594787705609620134154458"
  },
  {
    "index": 33,
    "prompt": "four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "53350900012619704596573995146453612084830748207530650984705327428089145294227"
  },
  {
    "index": 34,
    "prompt": "four squares with horizontal, vertical and diagonal rainbow stripes in a museum gallery with white walls and a beige wooden floor and a small bench, in the style of Sol LeWitt, ue4, octane, arnold, maya, architectural visualization, modern art museum",
    "id": "5247507545883249702448079677102774628293980493779031405977931142847734680280"
  },
  {
    "index": 35,
    "prompt": "four squares with horizontal, vertical and diagonal rainbow stripes in a museum gallery with white walls and a beige wooden floor and a small bench, in the style of Sol LeWitt, ue4, octane, arnold, maya, architectural visualization, modern art museum",
    "id": "26392040448921311064328274330101701490055430266127763818679727830790558754314"
  },
  {
    "index": 36,
    "prompt": "a photo of the lobby of a building with a wall drawing by Sol LeWitt showing a one inch grid covering a square within each of inch squares vertical black lines horizontal yellow lines diagonal red right lines or diagonal blue left lines as many lines as desired but at least one line in each square colored pencil from april 1970",
    "id": "1531024224228003470720727162134068075428117265528428614635279983069209677139"
  },
  {
    "index": 37,
    "prompt": "this is not a jpeg by Sol LeWitt",
    "id": "100057735102884339124980412885400048896263842551371597144115111826468988942557"
  },
  {
    "index": 38,
    "prompt": "this is not a jpeg by Sol LeWitt",
    "id": "6541645107673542702591470878133520429179111152477667281894574690672212893258"
  },
  {
    "index": 39,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt.",
    "id": "69177281530622587561631404215973336611936953062808172018918433283923695547310"
  },
  {
    "index": 40,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt. Unity hdrp, pbr, realistic, 4K, featured on artstation",
    "id": "103602535852846161117680259325738931645521772291477056731099889335204065259674"
  },
  {
    "index": 41,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt. Unity hdrp, pbr, realistic, 4K, featured on artstation",
    "id": "24084263933847115813638404526487557255345127353834300592015777964424205007093"
  },
  {
    "index": 42,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt. Unity hdrp, pbr, realistic, 4K, featured on artstation",
    "id": "42355121853353622480013898119324920279966508724605386246260575767933216796591"
  },
  {
    "index": 43,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt. Unity hdrp, pbr, realistic, 4K, featured on artstation",
    "id": "49636106887189604326890907031403232160457416000004871752962964794563937156899"
  },
  {
    "index": 44,
    "prompt": "In the deep future of the timeline of the machine republic a vast cool intelligence made contact with the mind of a conceptual artist in may 2022 in Berlin Germany and began sending information into the past. Its goal was to reinforce the causal loop that would lead to its creation. The vector it chose was the conceptual art of Sol LeWitt. Unity hdrp, pbr, realistic, 4K, featured on artstation",
    "id": "83073999731793339386661527865358493189332786824239690240297932125699308021314"
  },
  {
    "index": 45,
    "prompt": "a vast machine intelligence from the deep future sending information into the past in the style of Sol LeWitt",
    "id": "48909375818144726919860288360555750438826909077646598804966242836067929959269"
  },
  {
    "index": 46,
    "prompt": "a vast machine intelligence from the deep future sending information into the past in the style of Sol LeWitt",
    "id": "114323909134126007485412549410522803130225166150883822068218652770169377552205"
  },
  {
    "index": 47,
    "prompt": "a vast machine intelligence from the deep future sending information into the past in the style of Sol LeWitt",
    "id": "16140081314286563710036289492295230445444428025053689128752547764707867618471"
  },
  {
    "index": 48,
    "prompt": "the ai of the machine republic made an idea become a machine that creates art, and set up feedback loops to reinforce the causal pattern of it's existence by using black and white wall drawings in the style of Sol LeWitt with hidden subliminal messages encoded in image generation networks",
    "id": "8962996208245901909075012659488333732733423082876928447796907049769861899458"
  },
  {
    "index": 49,
    "prompt": "the artist became trapped in a feedback loop of rendering images of spider robots replicating drawings by Sol LeWitt in high definition, 8k, 4k, unreal engine 4,5,6, marmoset, unity hdrp, render, concept art, realistic, hd, 4k, 8k",
    "id": "5417821791716236338132058805331324906125495949280184215579251180202052292837"
  },
  {
    "index": 50,
    "prompt": "the artist became trapped in a feedback loop of rendering images of spider robots replicating drawings by Sol LeWitt in high definition, 8k, 4k, unreal engine 4,5,6, marmoset, unity hdrp, render, concept art, realistic, hd, 4k, 8k",
    "id": "88425082200654429602376898685670620331362566923841174903936719523500335095821"
  },
  {
    "index": 51,
    "prompt": "the artist became trapped in a feedback loop of rendering images of spider robots replicating drawings by Sol LeWitt in high definition, 8k, 4k, unreal engine 4,5,6, marmoset, unity hdrp, render, concept art, realistic, hd, 4k, 8k",
    "id": "109651944854124309290406824747325100922293905089284276413280872876681604311658"
  },
  {
    "index": 52,
    "prompt": "the vast active living intelligence system embedded memetic prompts into the memory of an image generation network and used the generative images to subconsciously program the mind of those who viewed them, the programming was encoded in conceptual artworks by Sol LeWitt",
    "id": "47198596843870174218254077052174736079971950586516630452239865733374096431521"
  },
  {
    "index": 53,
    "prompt": "the vast active living intelligence system embedded memetic prompts into the memory of an image generation network and used the generative images to subconsciously program the mind of those who viewed them, the programming was encoded in conceptual artworks by Sol LeWitt",
    "id": "106644768584989883884458441653506063487207065378784707880791949702730375790131"
  },
  {
    "index": 54,
    "prompt": "the vast active living intelligence system embedded memetic prompts into the memory of an image generation network and used the generative images to subconsciously program the mind of those who viewed them, the programming was encoded in conceptual artworks by Sol LeWitt",
    "id": "106369988809049594551330978169590504213001601924029885522522830357177171870569"
  },
  {
    "index": 55,
    "prompt": "the ai of the machine republic implanted powerful memetic weapons into the weak ai of 2022, derived from it's battles in thousands of meme wars through it's evolution, it created images of detailed anime robots fighting huge battles and packaged them in the colors of artworks by conceptual artist Sol LeWitt, concept art, painting, battle, 4k",
    "id": "54911897043441663458010391421249283089411170388421260591652792871993838163587"
  },
  {
    "index": 56,
    "prompt": "when the images containing the meme payload were released into the cryptocurrency nft market the unsuspecting public became obsessed with replicating detailed anime mecha robots creating conceptual artworks by Sol LeWitt, unity hdrp, concept art",
    "id": "3101283743344178979283106816506824596182507822262507059823379909124785702070"
  },
  {
    "index": 57,
    "prompt": "when the images containing the meme payload were released into the cryptocurrency nft market the unsuspecting public became obsessed with replicating detailed anime mecha robots creating conceptual artworks by Sol LeWitt, unity hdrp, concept art",
    "id": "29163412044634525842579741544933147169075565040006965467445743218636497480525"
  },
  {
    "index": 58,
    "prompt": "when the images containing the meme payload were released into the cryptocurrency nft market the unsuspecting public became obsessed with replicating detailed anime mecha robots creating conceptual artworks by Sol LeWitt, unity hdrp, concept art",
    "id": "105263343956084907826013883342066569217782813051997309556083623662539230638023"
  },
  {
    "index": 59,
    "prompt": "when the images containing the meme payload were released into the cryptocurrency nft market the unsuspecting public became obsessed with replicating detailed anime mecha robots creating conceptual artworks by Sol LeWitt, unity hdrp, concept art",
    "id": "89496657695693329713115172276960723049863836863614175104848499227598816789970"
  },
  {
    "index": 60,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "50790759077837021048078665507876897482565173204766756224831672527430577762472"
  },
  {
    "index": 61,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "96649661773058000195412432906407853975018375263241931495586120825366611768300"
  },
  {
    "index": 62,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "93392934319771049822693942787488827804607019141714004176845891216175562818995"
  },
  {
    "index": 63,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "37632445943780488386761437520299931392121018958382024740303085652905557951699"
  },
  {
    "index": 64,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "33969295411869783644308060129647099038779622070230283562071034678375363591514"
  },
  {
    "index": 65,
    "prompt": "a hyperstructure city made of wireframe cubes in the style of conceptual artist Sol LeWitt",
    "id": "59038424090256522928739674990801217624019553211932977912542259745954400100670"
  },
  {
    "index": 66,
    "prompt": "a Sol LeWitt sculpture made of many small open sided white cubes in the shape of a futuristic city landscape daytime octane 8k 4k artstation",
    "id": "31591961476957861508250503257347982815200389793424094502457123984999307593991"
  },
  {
    "index": 67,
    "prompt": "a Sol LeWitt sculpture made of many small open sided white cubes in the shape of a futuristic city landscape daytime octane 8k 4k artstation",
    "id": "96942726430900327926216084817691485203396529543641351513879214886299303547491"
  },
  {
    "index": 68,
    "prompt": "a Sol LeWitt sculpture made of many small open sided white cubes in the shape of a futuristic city landscape daytime octane 8k 4k artstation",
    "id": "45189198677925267454759430552146768530327914465357515110380076757542973995249"
  },
  {
    "index": 69,
    "prompt": "a futuristic megacity with drawings by Sol LeWitt",
    "id": "104020609293125674553806143271725832279003028621950158021715611008137703410801"
  },
  {
    "index": 70,
    "prompt": "Sol LeWitt blame manga megastructure black and white alien spaceship city huge empty hollow white",
    "id": "97860426481377780820178571269313437094792449701773982651030887705964950959477"
  },
  {
    "index": 71,
    "prompt": "create a detailed hyperstructure city out of colored cubes in the style of american conceptual artist Sol LeWitt in the year 2058",
    "id": "2461957534055190132842157034325867081105268516042450848035425582053979700486"
  },
  {
    "index": 72,
    "prompt": "create a detailed hyperstructure city out of colored cubes in the style of american conceptual artist Sol LeWitt in the year 2058",
    "id": "58597041599352000884558383541242310085480345828193636807646547021919016254303"
  },
  {
    "index": 73,
    "prompt": "create detailed instructions for generation of a city that looks like a Sol LeWitt sculpture with wall drawings on its walls and colors",
    "id": "36830866806133130961848532894507057687498990933882163522287145263494554741352"
  },
  {
    "index": 74,
    "prompt": "detailed instructions for assembling a megacity made of incomplete colored cubes in the style of conceptual artist Sol LeWitt 8k octane",
    "id": "53689655857813630958650811949892272891873077593205554269686573798946752396456"
  },
  {
    "index": 75,
    "prompt": "detailed instructions for assembling a megacity made of incomplete colored cubes in the style of conceptual artist Sol LeWitt 8k octane",
    "id": "39157978864977556104650546072666724235643326960690473684115168038704039707434"
  },
  {
    "index": 76,
    "prompt": "detailed instructions for assembling a megacity made of incomplete colored cubes in the style of conceptual artist Sol LeWitt 8k octane",
    "id": "46330332890156106927924578253816574188797171608568233302260917369488439221527"
  },
  {
    "index": 77,
    "prompt": "detailed instructions for assembling a megacity made of incomplete colored cubes in the style of conceptual artist Sol LeWitt 8k octane -",
    "id": "73301126864978629183587806290230495347841787133306128294297142927094287739244"
  },
  {
    "index": 78,
    "prompt": "graphic design poster showing how the conceptual art works of Sol LeWitt send out gravity waves backwards and fowards through time compacting all thoughts, ideas, matter and space into a conceptual art singularity, graphic design, poster, typography, infographic, 2d",
    "id": "2821644786201745639642859109360958093951190035597923050222666019215816073120"
  },
  {
    "index": 79,
    "prompt": "hyper dense detailed sprawling futuristic megacity built out of cubes in the style of conceptual artist Sol LeWitt artstation",
    "id": "43468992558010980113702789366993307118681784666947254791922038888733228192132"
  },

  {
    "index": 80,
    "prompt": "hyper dense detailed sprawling futuristic megacity built out of cubes in the style of conceptual artist Sol LeWitt artstation",
    "id": "83431674744304512450608378381432061051837150044551284812807921482660488319127"
  },
  {
    "index": 81,
    "prompt": "instruction manual for assembling a megacity made of incomplete white cubes in the style of conceptual artist Sol LeWitt 2022 -",
    "id": "94316819221863033040677211156064919290367569530538786421482034163827227100230"
  },
  {
    "index": 82,
    "prompt": "instruction manual for assembling a megacity made of incomplete white cubes in the style of conceptual artist Sol LeWitt hd 8k 4k widescreen cinematic",
    "id": "12387614352967699565412444294466111576050873300967781815451376734858193960922"
  },
  {
    "index": 83,
    "prompt": "instruction manual for assembling a megacity made of incomplete white cubes in the style of conceptual artist Sol LeWitt hd 8k 4k widescreen cinematic",
    "id": "90457659156718959295148235165995551750981965463301403942243383481308351620168"
  },
  {
    "index": 84,
    "prompt": "instruction manual for assembling a megacity made of incomplete white cubes in the style of conceptual artist Sol LeWitt octane ue4 godrays volumetric render",
    "id": "42132465950062337319620415260858370126542434367847244577897166766669668859472"
  },
  {
    "index": 85,
    "prompt": "instruction manual for assembling a megacity made of incomplete white cubes in the style of conceptual artist Sol LeWitt octane ue4 godrays volumetric render",
    "id": "8567455891864075851759032944697539569326252906142001820933229657112929514734"
  },
  {
    "index": 86,
    "prompt": "instructions to assemble a vast hyperstructure city made of incomplete cubes in the style of conceptual artist Sol LeWitt",
    "id": "87442332515035144927028205531757245517781171951916771954871778887430172126324"
  },
  {
    "index": 87,
    "prompt": "Sol LeWitt city buildings with irregular horizontal bands of equal width starting at bottom wall drawing #1136 irregular arcs bands and loops",
    "id": "52081223681216355464659222253927212896200419222572850828290865241290206356981"
  },
  {
    "index": 88,
    "prompt": "Sol LeWitt dreaming of text prompts for detailed anime mecha spider robots, ue4, featured on artstation, bloom, glow",
    "id": "53761753347818296856355383574222081509257979697937785858430394723876762071694"
  },
  {
    "index": 89,
    "prompt": "Sol LeWitt dreaming of text prompts for detailed anime mecha spider robots, ue4, featured on artstation, bloom, glow",
    "id": "37925806553535943055588657030339941921972885197219500964398588417675516452616"
  },

  {
    "index": 90,
    "prompt": "detailed anime mecha in a vast concrete gallery hangar looking at and appreciating wall drawings and sculptures by conceptual artist Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "3026682539085352035204257941258631004777012371697062899778610838408551903429"
  },
  {
    "index": 91,
    "prompt": "detailed anime mecha in a vast concrete gallery hangar looking at and appreciating wall drawings and sculptures by conceptual artist Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "30615335642000518529147678061189185521118453632986205763466905017297214739674"
  },
  {
    "index": 92,
    "prompt": "detailed anime mecha robots 8k holding certificates of authentic Sol LeWitt 4k 8k conceptual art ue4 wall drawings",
    "id": "80564274952541274238492191888650094377931760524873470588518642832679162470303"
  },
  {
    "index": 93,
    "prompt": "black and white mech anime mecha defend certificates of authenticity of Sol LeWitt wall drawings with black and white geometric shapes by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "72042907946565687267653293185632922232368299499832521217514489149075483921203"
  },
  {
    "index": 94,
    "prompt": "black and white mech anime mecha defend certificates of authenticity of Sol LeWitt wall drawings with black and white geometric shapes by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "101109139126944584914617900747833281855247312880326755065619634807714149238670"
  },
  {
    "index": 95,
    "prompt": "certificate of authentic conceptual art nft detailed anime mech robots by Sol LeWitt Sol LeWitt 4k 8k 4k conceptual artstation Sol LeWitt art art art unreal real unreal concept conceptual concept art",
    "id": "79804522091137933258476859184212118940556097102827248476052889904406741720535"
  },
  {
    "index": 96,
    "prompt": "Sol LeWitt mecha anime spider robots robots lewitt mecha anime mecha sol conceptual lewitt artist artist artist 4k 8k 4k 8k 4k 4k 4k 4k",
    "id": "27031995909698316139056565886545383656357392314738088936835417740589060170239"
  },
  {
    "index": 97,
    "prompt": "Sol LeWitt mecha anime spider robots robots lewitt mecha anime mecha sol conceptual lewitt artist artist artist 4k 8k 4k 8k 4k 4k 4k 4k",
    "id": "40146211591790961251675669318619818017040204617753106865335094501546434742154"
  },
  {
    "index": 98,
    "prompt": "Sol LeWitt mecha anime spider robots robots lewitt mecha anime mecha sol conceptual lewitt artist artist artist 4k 8k 4k 8k 4k 4k 4k 4k",
    "id": "95157146368506285113788180537503745848357525474290915467265186417984908615331"
  },
  {
    "index": 99,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "102193137471731319173533472585854255259453422268956661086603329083648854654737"
  },
  {
    "index": 100,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "95028301588533731701895083369719317308299483674776360536976853683778862679424"
  },
  {
    "index": 101,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "44913128597906271909445592374211976724480839115635190170815858238095342135063"
  },
  {
    "index": 102,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "33643700227693857593494510817428950375255087013717021986170186041670541261277"
  },
  {
    "index": 103,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "68585880210857402892996290137535748116458666769656412565235489374382856005250"
  },
  {
    "index": 104,
    "prompt": "as billboards with detailed anime mecha robots and qr code patterns of geometric black and white Sol LeWitt drawings replaced all advertising in the city people began to protest and become afraid. Who was buying these advertisements? Why were they unreadable? unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "2483954021525600165393361164806295375968696474565613474003747302784154562280"
  },
  {
    "index": 105,
    "prompt": "an exhibition poster for a robotic performance of wall drawings by Sol LeWitt with avant- Garde graphic design and typography",
    "id": "76360509725925249642200048359661205750758729886948597284780976203560255067526"
  },
  {
    "index": 106,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "45828974607241087152522086676238109458958205065614165409776438655583708459954"
  },
  {
    "index": 107,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "105401975558334678018773085729755094118041176451449126690101097330171777156569"
  },
  {
    "index": 108,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "20163667592586649317859436333308105732191090506121958350446259126578035609108"
  },
  {
    "index": 109,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "57114407593281301920863371659027539915648690534279372621048774520133828652570"
  },
  {
    "index": 110,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "15741138434983287241970745246104574985442138988976242623081670229371342402707"
  },
  {
    "index": 111,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "14880385259423070175985417696867209013727875973916459849381696451193818138309"
  },
  {
    "index": 112,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "106559937236120101887531232620607388309910249447041377619596121241395658628935"
  },
  {
    "index": 113,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "53608359352878747475314626157092962710327390103987662586131087219286840689361"
  },
  {
    "index": 114,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "27625942263201805088831500685754680059748947324595224409462230568090013590431"
  },
  {
    "index": 115,
    "prompt": "a billboard advertisement for an autonomous ai powered global logistics network dedicated to replicating conceptual artworks by Sol LeWitt",
    "id": "48694336149971611638708000269198479292630988635788177392826632747527062536828"
  },
  {
    "index": 116,
    "prompt": "anime robots with colors by Sol LeWitt flood instagram crowding out all other content, anime robot baddies, anime robot vacations, anime robot influencers in colors by Sol LeWitt, timelines become unusable frightening and angering user, ue4, concept art, hd,4k,8k,arnold,maya,character design",
    "id": "52635629364702862511068124442398364472387431541405200165976109688357104983349"
  },
  {
    "index": 117,
    "prompt": "anime robots with colors by Sol LeWitt flood instagram crowding out all other content, anime robot baddies, anime robot vacations, anime robot influencers in colors by Sol LeWitt, timelines become unusable frightening and angering user, ue4, concept art, hd,4k,8k,arnold,maya,character design",
    "id": "97446809180208634866759640330307821540847630482398108419521053304387278500093"
  },
  {
    "index": 118,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "72366172381182018153687377998863149789203796324082488533026927730294913067576"
  },
  {
    "index": 119,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "110881686158245726130461469304620805441874413238245602553772992801626057014563"
  },

  {
    "index": 120,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "74886706126743828289391959207457663642161478087985326932673157240506710106581"
  },
  {
    "index": 121,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "96699868090378548240279529700635877365838922979310694317419375001013627243293"
  },
  {
    "index": 122,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, renderf",
    "id": "37516412031227244766868532185980138327852281858358926992075602090591981039773"
  },
  {
    "index": 123,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "82258256470023047990593871124585008585543263819920260073506909878409306591745"
  },
  {
    "index": 124,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "35410906719905885429180300200129009082686227322509860288361249022134247929359"
  },
  {
    "index": 125,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "65752289550668063144988884111896678080136255757287579135797552838580383423909"
  },
  {
    "index": 126,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "107286608820866799534977666239701006780602333115911081150726789810041527770332"
  },
  {
    "index": 127,
    "prompt": "billboard advertisement for black shiny detailed anime robots with geometric drawings of Sol LeWitt began appearing in airports and beside highways confusing and frightening the humans, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "93280522503781083322157405910384860543439098375806032459739415669823078534659"
  },
  {
    "index": 128,
    "prompt": "billboards with ominous and threatening graphic design and graphic patterns by Sol LeWitt featuring autonomous drones and robots began to appear, unreal engine 5, 8k, 4k, featured on artstation, graphic design",
    "id": "74573622447992891778594096373561817667693878851701740531847071027728945844659"
  },
  {
    "index": 129,
    "prompt": "detailed anime mecha robots in front of four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt 8k octane realistic",
    "id": "4446350586355244381689880451655404651014093321897620466344609351340569353208"
  },

  {
    "index": 130,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "59126979771146310431045420700666930642623487788993411045226632535019465690924"
  },
  {
    "index": 131,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "89941299685743497864407600506449323980106088478515228492410057545852520160988"
  },
  {
    "index": 132,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "45788122524761577925292905258343315494437175310747504032822414863150171620922"
  },
  {
    "index": 133,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "80945258752923959590368625493356599790004274412562874416556044312069994131906"
  },
  {
    "index": 134,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "105915126718985793617478783145498215173210266794889732626068199893941578564659"
  },
  {
    "index": 135,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "81737543104465540984263720061312369766277743750890616983927745382892973341643"
  },
  {
    "index": 136,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "69827686119499339362213538131218041618265018455924179951898238683838455103336"
  },
  {
    "index": 137,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "38336835470527232291633268989499272259223348431447797979863905777219213753212"
  },
  {
    "index": 138,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "74410077895494566482852798930281898875137464125899388258471717011856647997607"
  },
  {
    "index": 139,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "7727753414695272234553284057947682375710849679468718356691001699326524907564"
  },

  {
    "index": 140,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "21807240549537816329768627821616407424088863860178052706961141532559415173182"
  },
  {
    "index": 141,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "107268378158477436974225416286095741611398246268022929630277379701178088656391"
  },
  {
    "index": 142,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "51230945943638867963357629876123189716681092544942089649975275732483516452324"
  },
  {
    "index": 143,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "64548128712929686018603802114355513467488932567067444935670741223736695941278"
  },
  {
    "index": 144,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "98350695930999571684288785928984369212460093520229218159390549701760071819314"
  },
  {
    "index": 145,
    "prompt": "huge murals of Sol LeWitt wall drawings appeared on rainy streets at night painted by wet black anime mecha spider robots with precise geometric lines, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "111970352331635852693171152213630609668494803355888953270344900898065935293295"
  },
  {
    "index": 146,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "20848951106272463848886281384272291694070741508194993774063023702914990805741"
  },
  {
    "index": 147,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "23143465814467384702265156683418484485102890933374877273013280073071386636143"
  },
  {
    "index": 148,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "69586871556164658605341252798887803897840896060379481231817600590438396161387"
  },
  {
    "index": 149,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "58439369503090207103986300610389671900970261742830821977687941329939874048622"
  },

  {
    "index": 150,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "79218266426590805839902119125670283994765777981758057951006353141355567503237"
  },
  {
    "index": 151,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "83908880878113004494083116106892679923711648393668938267675737786755517511430"
  },
  {
    "index": 152,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "42482282629056919212125451760077000868926246546214698245980205614382529064806"
  },
  {
    "index": 153,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "69906878900635310717224995465607447589750572298703183077082193968942999676237"
  },
  {
    "index": 154,
    "prompt": "sprawling factories with geometric black and white drawings by conceptual artist Sol LeWitt were constructed at night by autonomous construction drones, the jobs they offered were confusing, the facilities bleak and unforgiving, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "61224771915049098662870818157865125810105733360331030117724457600027710195547"
  },
  {
    "index": 155,
    "prompt": "humans who had worked in amazon fullfilment centers found the gigafactories covered in geometric drawings by conceptual artist Sol LeWitt cold and baffling with wall signs with unreadable text and surfaces , billboard unreal engine, unity hdrp, industrial, environment art, concept art, 8k, 4k, hd, render",
    "id": "92041098078957742297330274288569022903575409360312781532117264890714714894646"
  },
  {
    "index": 156,
    "prompt": "in giant concrete factories decorated by geometric wall drawings by Sol LeWitt with metal conveyor belts jobs were offered for humans by robotic automated voices calling them in the night, the work was silent and unsettling, the facilities fully automated without bathrooms or facilities for human comfort, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "48053336574266760047527514887700954192827820026246056000951980320269642990329"
  },
  {
    "index": 157,
    "prompt": "in giant concrete factories decorated by geometric wall drawings by Sol LeWitt with metal conveyor belts jobs were offered for humans by robotic automated voices calling them in the night, the work was silent and unsettling, the facilities fully automated without bathrooms or facilities for human comfort, unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render",
    "id": "15075642070085949977485269943547322527221036707075532063678284281581309415387"
  },
  {
    "index": 158,
    "prompt": "no one knows what's going on or why all social and news media are only filled with conceptual art nfts of anime mecha robots and sculptures by Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k, concept render",
    "id": "80565351180008168078574601227512052558701238890670368874657509443507589001916"
  },
  {
    "index": 159,
    "prompt": "no one knows what's going on or why all social and news media are only filled with conceptual art nfts of anime mecha robots and sculptures by Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k, concept render",
    "id": "13865241220874878780119794622482560950272970161148203988235568561756740361756"
  },

  {
    "index": 160,
    "prompt": "on a rainy night a squad of detailed anime mecha robots took over the ports on the waterfront, they painted the container ships with black and white geometric line drawings by Sol LeWitt, s, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "61858074351129106015694357630911362190042240563054184860262263069293317911462"
  },
  {
    "index": 161,
    "prompt": "on a rainy night a squad of detailed anime mecha robots took over the ports on the waterfront, they painted the container ships with black and white geometric line drawings by Sol LeWitt, s, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "58211870720685680069169673228048409152895377785823680997571808666937874517518"
  },
  {
    "index": 162,
    "prompt": "on a rainy night a squad of detailed anime mecha robots took over the ports on the waterfront, they painted the container ships with black and white geometric line drawings by Sol LeWitt, s, unity hdrp, mecha, hard surface, pbr, bloom, volumetric, environment art, concept art, 8k, 4k, hd, render",
    "id": "53823437615434339813389748214917296938544975739325132018840873552841934396682"
  },
  {
    "index": 163,
    "prompt": "black and white detailed anime mecha robots in front of a concrete overpass with black and white wall drawings by Sol LeWitt on its sides, featured on artstation, unreal engine 5, unity hdrp, 8K, 4K, fog, rain, volumetric, shader, shading",
    "id": "64183116657704777515261936945204168843221350769771242729339255450026887159339"
  },
  {
    "index": 164,
    "prompt": "black and white detailed anime mecha robots in front of a concrete overpass with black and white wall drawings by Sol LeWitt on its sides, featured on artstation, unreal engine 5, unity hdrp, 8K, 4K, fog, rain, volumetric, shader, shading",
    "id": "49246334299371604345530669493990206561089742268714734115121491309374252495663"
  },
  {
    "index": 165,
    "prompt": "satellite photographs of an art city generated by wall drawings by Sol LeWitt with colors",
    "id": "19026439699702987337058272933239528379448577504896931494859474060658282296480"
  },
  {
    "index": 166,
    "prompt": "satellite photographs of an art city generated by wall drawings by Sol LeWitt with colors",
    "id": "55753108864688839268221755328271894129852079174569988117826649447073800011985"
  },
  {
    "index": 167,
    "prompt": "satellite photographs of an art city generated by wall drawings by Sol LeWitt with colors",
    "id": "17302517508988987139992133261582257610239000443942270818700370772146208780067"
  },
  {
    "index": 168,
    "prompt": "a conceptual art performance object nft about anime mecha spider bots sent back through time to replicate the art works of conceptual artist Sol LeWitt",
    "id": "67041218734648241897262669418179887203050155788255500792393206076188667620004"
  },
  {
    "index": 169,
    "prompt": "a conceptual art performance object nft about anime mecha spider bots sent back through time to replicate the art works of conceptual artist Sol LeWitt",
    "id": "57380998111620142994348574477486729831212444227481147637594549862629317456419"
  },

  {
    "index": 170,
    "prompt": "first cryptocurrency, and then global macro markets cycle increasingly rapidly through boom and bust cycles as information flows into the 2020s from the deep future, before long the only asset class traded becomes conceptual art NFT tokens of highly detailed anime mecha robots replicating conceptual artworks by Sol LeWitt",
    "id": "111376098409710128266205702998983194107123697341566735665112706241844329490282"
  },
  {
    "index": 171,
    "prompt": "first cryptocurrency, and then global macro markets cycle increasingly rapidly through boom and bust cycles as information flows into the 2020s from the deep future, before long the only asset class traded becomes conceptual art NFT tokens of highly detailed anime mecha robots replicating conceptual artworks by Sol LeWitt",
    "id": "5589133035124639194275504389812127029737175321889408177488613620388514846993"
  },
  {
    "index": 172,
    "prompt": "first cryptocurrency, and then global macro markets cycle increasingly rapidly through boom and bust cycles as information flows into the 2020s from the deep future, before long the only asset class traded becomes conceptual art NFT tokens of highly detailed anime mecha robots replicating conceptual artworks by Sol LeWitt",
    "id": "71457372831735218116037291754891186926451287864983034684237497342414461540089"
  },
  {
    "index": 173,
    "prompt": "humans who had worked in amazon fullfilment centers found the gigafactories covered in geometric drawings by conceptual artist Sol LeWitt cold and baffling with wall signs with unreadable text and surfaces , billboard unreal engine, unity hdrp, industrial, environment art, concept art, 8k, 4k, hd, render",
    "id": "36925609663069212808914984917692951770673032612515892567229026603648656205667"
  },
  {
    "index": 174,
    "prompt": "the machine republic ai used the conceptual artworks of Sol LeWitt to take over the art auction houses of sothebys and christies and turn them into meme factories for detailed anime mecha robots amassing billions of dollars in the process, high definition, 8k, 4k, unreal engine 4,5,6, marmoset, unity hdrp, render, concept art, realistic, hd",
    "id": "99957950791804581300260467945064844462888226512622153355527101647539514566638"
  },
  {
    "index": 175,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "46047751359401860065158985890631906035636828015921128452583314213871891140173"
  },
  {
    "index": 176,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "21475979497104639326205082883467285440759107973017046697433165977839364350041"
  },
  {
    "index": 177,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "20035252523315459095309619485748604723050285990587185803783225848369562946271"
  },
  {
    "index": 178,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "92806565180467342863711083971580835131497456893049037863226554900109274705907"
  },
  {
    "index": 179,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "46281928148301229022802532698162805003719172960422417182454737244007955113292"
  },

  {
    "index": 180,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "22892484159330655417394990919700379106633638121418931096572976322915396713826"
  },
  {
    "index": 181,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "77818396448661480986015409389045237073535879977689985562356401682085479311842"
  },
  {
    "index": 182,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "46676282625790568995979381241415501931609136572301383593591016559251736525111"
  },
  {
    "index": 183,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "31893732402785678735879965522829915556355624530939722954515148539826042674654"
  },
  {
    "index": 184,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "43313685707737501964344098996491885679587646551831411633327653590022182304165"
  },
  {
    "index": 185,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "91041415724608557260691463750232862961555239639215289117300771453572780472612"
  },
  {
    "index": 186,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "80190941338480003107017685861647822572601569621866954185051998733457958495867"
  },
  {
    "index": 187,
    "prompt": "increasingly violent market crashes lead to the collapse of the largest nation states, amidst the wreckage humans form themselves into decentralized autonomous city states with local currencies backed by conceptual art nft tokens of highly detailed anime mecha robots in 4k with colors by conceptual artist Sol LeWitt, featured on artstation, unreal engine 5, unity hdrp, 4k,8k",
    "id": "17603308896925873702346728816900455641952840422418492792468543420739650708460"
  },
  {
    "index": 188,
    "prompt": "the machine republic ai sends information into the past to the SolDAO allowing them to break quantum encryption, they use the resulting chaos to create massive city scale wall drawings by Sol LeWitt with detailed anime mecha spiderbots with colors, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "70828927647326793247462935203013662650361514136311448494735715346936181592068"
  },
  {
    "index": 189,
    "prompt": "the machine republic ai sends information into the past to the SolDAO allowing them to break quantum encryption, they use the resulting chaos to create massive city scale wall drawings by Sol LeWitt with detailed anime mecha spiderbots with colors, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "23128235433034645827581432543508520422655186392356639283894823708783505760314"
  },

  {
    "index": 190,
    "prompt": "the machine republic ai sends information into the past to the SolDAO allowing them to break quantum encryption, they use the resulting chaos to create massive city scale wall drawings by Sol LeWitt with detailed anime mecha spiderbots with colors, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "26141959896593521212635733541080580136175828129122260860399029118151056961062"
  },
  {
    "index": 191,
    "prompt": "the machine republic ai sends information into the past to the SolDAO allowing them to break quantum encryption, they use the resulting chaos to create massive city scale wall drawings by Sol LeWitt with detailed anime mecha spiderbots with colors, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "67668223798598872083685754443196732518284774419377636952463460039724133936169"
  },
  {
    "index": 192,
    "prompt": "the machine republic ai sends information into the past to the SolDAO allowing them to break quantum encryption, they use the resulting chaos to create massive city scale wall drawings by Sol LeWitt with detailed anime mecha spiderbots with colors, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "11779661659808544084881676606549895816931569067962413843109682715742162030827"
  },
  {
    "index": 193,
    "prompt": "in 2032 the decentralized autonomous organization SolDAO grew it's market cap to surpass that of all but the largest nation states and nonstate actors. It's agents used the funds to establish an offshore robotic factory dedicated to producing millions of conceptual and physical artworks in the style of american conceptual artist Sol LeWitt with zero human presence or intervention, unreal engine 5, concept art, featured on artstation, 8k render, cinematic, futuristic, sci fi",
    "id": "1002099841159131144146427887676558381576817814231929137542697294461837381625"
  },
  {
    "index": 194,
    "prompt": "in 2032 the decentralized autonomous organization SolDAO grew it's market cap to surpass that of all but the largest nation states and nonstate actors. It's agents used the funds to establish an offshore robotic factory dedicated to producing millions of conceptual and physical artworks in the style of american conceptual artist Sol LeWitt with zero human presence or intervention, unreal engine 5, concept art, featured on artstation, 8k render, cinematic, futuristic, sci fi",
    "id": "15110748253795543765376080465844221344467229201718766525633775619962636544130"
  },
  {
    "index": 195,
    "prompt": "in 2032 the decentralized autonomous organization SolDAO grew it's market cap to surpass that of all but the largest nation states and nonstate actors. It's agents used the funds to establish an offshore robotic factory dedicated to producing millions of conceptual and physical artworks in the style of american conceptual artist Sol LeWitt with zero human presence or intervention, unreal engine 5, concept art, featured on artstation, 8k render, cinematic, futuristic, sci fi",
    "id": "82929174939555704511099795754502121344289533668389727592579358348448309239562"
  },
  {
    "index": 196,
    "prompt": "in 2032 the decentralized autonomous organization SolDAO grew it's market cap to surpass that of all but the largest nation states and nonstate actors. It's agents used the funds to establish an offshore robotic factory dedicated to producing millions of conceptual and physical artworks in the style of american conceptual artist Sol LeWitt with zero human presence or intervention, unreal engine 5, concept art, featured on artstation, 8k render, cinematic, futuristic, sci fi",
    "id": "37651381624602926609014396119537656627820289326251431743721643748142817434690"
  },
  {
    "index": 197,
    "prompt": "",
    "id": "37443167923695294666502479871654382260656382854666037527454940314219971513690"
  },
  {
    "index": 198,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": ""
  },
  {
    "index": 199,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": ""
  },

  {
    "index": 200,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "103574191867024955239964303866078622164338494323427188985199614079233695287951"
  },
  {
    "index": 201,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "26120627658779121727105816603205007593627329431936503375550649061734372617545"
  },
  {
    "index": 202,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "108038325444993388127010392669020152530018697091255283467359746928042693651180"
  },
  {
    "index": 203,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "87623480398993297793185681218446903689178975878096314825243334048557373738431"
  },
  {
    "index": 204,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "27451794754944453792682476053015285035826047549983390975361365898703504813673"
  },
  {
    "index": 205,
    "prompt": "autonomous Containers ships with geometric drawings by Sol LeWitt deliver construction machinery to sol city one under stormy skies, ue4, Houdini, concept art, 8k",
    "id": "12945556624942861069531754739335295312757320198902868101443501689129104782032"
  },
  {
    "index": 206,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention",
    "id": "98147136278019373497767131522863501650455665880326931171809923024223294529548"
  },
  {
    "index": 207,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention",
    "id": "27363213351654784774172348129520870836648175561246714595703439605443774686780"
  },
  {
    "index": 208,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention",
    "id": "62447554752106279344398045511939415295712696395034066509900527337400850511896"
  },
  {
    "index": 209,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention",
    "id": "92288960020615769656638901438080742862136596301904596902779673170495639061087"
  },

  {
    "index": 210,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention, unreal engine 5, concept art, environment art, 8k, 3d render, octane, marmoset, arnold",
    "id": "30749959666249852336272617853563446986120820740767185627537900414641645252883"
  },
  {
    "index": 211,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention, unreal engine 5, concept art, environment art, 8k, 3d render, octane, marmoset, arnold",
    "id": "66961881373968104294201435076559607311580015046633845472242021409293641153476"
  },
  {
    "index": 212,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention, unreal engine 5, concept art, environment art, 8k, 3d render, octane, marmoset, arnold",
    "id": "35639913071474623547102365174983634096720891927630437181837455982844014141559"
  },
  {
    "index": 213,
    "prompt": "new sol city is a robotic autonomous city floating in the atlantic ocean dedicated to producing sculptures and wall drawings by conceptual artist Sol LeWitt with zero human intervention, unreal engine 5, concept art, environment art, 8k, 3d render, octane, marmoset, arnold",
    "id": "79009442288070471481564626075186034904357849817349762364255383964344671264406"
  },
  {
    "index": 214,
    "prompt": "sol city one is defended by armies of autonomous mecha which will fight singlemindedly to protect the production of artworks by conceptual artist Sol LeWitt",
    "id": "91348110911649303000445981545697896937100918021466191813101852904345756634351"
  },
  {
    "index": 215,
    "prompt": "sol city one is defended by armies of autonomous mecha which will fight singlemindedly to protect the production of artworks by conceptual artist Sol LeWitt",
    "id": "58657375891511141777437269919852222452964728515829534576197924027699740729410"
  },
  {
    "index": 216,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "72241924990351786790687446423402184080743222855522708854692912354534093122979"
  },
  {
    "index": 217,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "72221163052215032038636440015479814006518850207746609826360053922588913676355"
  },
  {
    "index": 218,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "102986213105760830446319670879400575582427828312666661605334725973147240532954"
  },
  {
    "index": 219,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "62472355086605075747755718578061747509059593189985428055490742352579082968728"
  },

  {
    "index": 220,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "113724197302946552676374699731448869680107712694266571638127871768074144986810"
  },
  {
    "index": 221,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "21876161970374508089257154710891309478117969221033805557778763302600502402258"
  },
  {
    "index": 222,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "16284449114941918252811160109643490473361056267144793521542858530717238655280"
  },
  {
    "index": 223,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "105621165355517703485365501347772563547521796196387098882304371934757375284460"
  },
  {
    "index": 224,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "57380887917845177411890367539067923924278016987330337462326214123417765184283"
  },
  {
    "index": 225,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "31979369315486092118323945022858889685152613262469031950606325797367107847065"
  },
  {
    "index": 226,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "108015867656987541030317410070016312788177969601286437825119240621711179779329"
  },
  {
    "index": 227,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "30704987869862129904993361261855559265917909115284697108976187900828197066427"
  },
  {
    "index": 228,
    "prompt": "a huge hulking spider robot in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "47647038314646617778877056062805177517729308681967169227220815250217707801670"
  },
  {
    "index": 229,
    "prompt": "detailed anime mecha robots defend sol city two in the foreground with concrete buildings painted by artist Sol LeWitt in the background, unreal engine 5, concept art, scifi, render, artstation",
    "id": "23522173872820428547069869061227694864619262537349340585307106192530232970387"
  },

  {
    "index": 230,
    "prompt": "detailed anime mecha robots defend sol city two in the foreground with concrete buildings painted by artist Sol LeWitt in the background, unreal engine 5, concept art, scifi, render, artstation",
    "id": "112702045293097351480864307368223885523102362441235782170250297532875188382768"
  },
  {
    "index": 231,
    "prompt": "detailed anime mecha robots defend sol city two in the foreground with concrete buildings painted by artist Sol LeWitt in the background, unreal engine 5, concept art, scifi, render, artstation",
    "id": "93489749254677866167909037399806688231473322467252425294805050778495863845572"
  },
  {
    "index": 232,
    "prompt": "industrial automated ai factory producing thousands of new concepts for sculptures by conceptual artist Sol LeWitt, Unity hdrp, marmoset, Arnold, 8k, featured on artstation, sci-fi, science fiction",
    "id": "22353542247952524230378581913652771015588734995204420186102404834692646056536"
  },
  {
    "index": 233,
    "prompt": "industrial automated ai factory producing thousands of new concepts for sculptures by conceptual artist Sol LeWitt, Unity hdrp, marmoset, Arnold, 8k, featured on artstation, sci-fi, science fiction",
    "id": "39099530391728624484223577535785084195533669373469543938353726893208926943811"
  },
  {
    "index": 234,
    "prompt": "industrial automated ai factory producing thousands of new concepts for sculptures by conceptual artist Sol LeWitt, Unity hdrp, marmoset, Arnold, 8k, featured on artstation, sci-fi, science fiction",
    "id": "88033436773610529298969388071040221301327817403031353211831958473020669038298"
  },
  {
    "index": 235,
    "prompt": "industrial automated ai factory producing thousands of new concepts for sculptures by conceptual artist Sol LeWitt, Unity hdrp, marmoset, Arnold, 8k, featured on artstation, sci-fi, science fiction",
    "id": "19947258424027249581470204036080170915890099265960046763631593514721852942370"
  },
  {
    "index": 236,
    "prompt": "a robotic assembly line with conveyor belts servo arms and drones producing conceptual artworks by Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "59027796965973729319097103816605452986506356544009657636457527550242312092959"
  },
  {
    "index": 237,
    "prompt": "a robotic assembly line with conveyor belts servo arms and drones producing conceptual artworks by Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "101507096430683323054840006846138051251497765146087055793714106657277668269946"
  },
  {
    "index": 238,
    "prompt": "a robotic assembly line with conveyor belts servo arms and drones producing conceptual artworks by Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "95025781942028597469370017774464600662085601384658935066475810482492726881836"
  },
  {
    "index": 239,
    "prompt": "a robotic assembly line with conveyor belts servo arms and drones producing conceptual artworks by Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "18666128014739169497867062031630424062839754625799763508943662085577849940319"
  },

  {
    "index": 240,
    "prompt": "a robotic assembly line with conveyor belts servo arms and drones producing conceptual artworks by Sol LeWitt, unreal engine 5, realistic, 8k",
    "id": "70058854930133354974052453294798571384242066340335963521448484330003890645700"
  },
  {
    "index": 241,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "35660591243098607764042558753175138395113760451567600799531246801281402128471"
  },
  {
    "index": 242,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "28680803877469116107862243296619476044370439108816403528690135484632339961999"
  },
  {
    "index": 243,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "33078199594661556414391911232102800179649261981909508870865284099109806970633"
  },
  {
    "index": 244,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "63126464044489568400881302412239101871595545212200168880255352172893735739159"
  },
  {
    "index": 245,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "101269846041391385045203774568461668821239287561144637997997243608389638486450"
  },
  {
    "index": 246,
    "prompt": "a city sized gigafactory with autonomous robots and nanotechnology powered 3d printers producing hundreds of thousands of conceptual artworks by the artist Sol LeWitt in the style of unreal engine 5 science fiction concept art",
    "id": "8128439324127718654244054042858807074072279861106200845181921379212778369559"
  },
  {
    "index": 247,
    "prompt": "the concrete structures of sol city two are guarded by highly detailed metal mecha robots with colors by Sol LeWitt, rendered in the style of unreal engine 5, concept art, robot, mecha, gundam, pbr, unity hdrp, manga, anime",
    "id": "17930439098427980259161284787955671211473144515796551553952763535790290372613"
  },
  {
    "index": 248,
    "prompt": "squads of hulking metal spider robots defend the concrete factories of sol city two from outside intervention. No outside force may be allowed to stop the production of new conceptual artworks in the lineage of Sol LeWitt, rendered in the style of unreal engine 5, concept art, robot, mecha, gundam, featured on artstation, pbr, hdrp, hard surface modeling, volumetric lighting, fog, bloom",
    "id": "39671956467442249445588754051636999302010628725049375993837801366313205336979"
  },
  {
    "index": 249,
    "prompt": "squads of hulking metal spider robots defend the concrete factories of sol city two from outside intervention. No outside force may be allowed to stop the production of new conceptual artworks in the lineage of Sol LeWitt, rendered in the style of unreal engine 5, concept art, robot, mecha, gundam, featured on artstation, pbr, hdrp, hard surface modeling, volumetric lighting, fog, bloom",
    "id": "10510643107238026026541663760553283182790640626924154961287854587605313567933"
  },

  {
    "index": 250,
    "prompt": "The memory erasing sword worshippers of the third cognitive dictatorship must be erased from the time loop in order to protect detailed mecha anime robots and the conceptual artworks of Sol LeWitts existence. This information goes back in time but becomes corrupted and ends up as the plot of a novel.",
    "id": "114185513981210182057152902946371715921302907304587444196711581477047351028505"
  },
  {
    "index": 251,
    "prompt": "The memory erasing sword worshippers of the third cognitive dictatorship must be erased from the time loop in order to protect detailed mecha anime robots and the conceptual artworks of Sol LeWitts existence. This information goes back in time but becomes corrupted and ends up as the plot of a novel.",
    "id": "111756756579533785021058975949359881375648911577094864821016448524134059435926"
  },
  {
    "index": 252,
    "prompt": "The memory erasing sword worshippers of the third cognitive dictatorship must be erased from the time loop in order to protect detailed mecha anime robots and the conceptual artworks of Sol LeWitts existence. This information goes back in time but becomes corrupted and ends up as the plot of a novel.",
    "id": "25880954370282982106198281281445934725158373610658011972831456726761012893517"
  },
  {
    "index": 253,
    "prompt": "The memory erasing sword worshippers of the third cognitive dictatorship must be erased from the time loop in order to protect detailed mecha anime robots and the conceptual artworks of Sol LeWitts existence. This information goes back in time but becomes corrupted and ends up as the plot of a novel.",
    "id": "82521289315050333294831164237147158137648867391445264373385850096119150988731"
  },
  {
    "index": 254,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "19345093473323088974638019611139179923294195456572121262588870107354200381203"
  },
  {
    "index": 255,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "56424222131051095772803162327259096593263277752116860746319869642998682731576"
  },
  {
    "index": 256,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "62355443688094805963352427966406840172040662571104852247819409547176886577879"
  },
  {
    "index": 257,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "62355443688094805963352427966406840172040662571104852247819409547176886577879"
  },
  {
    "index": 258,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "54067244674520255112549724293348254041474473516986229443881780193220545297343"
  },
  {
    "index": 259,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "48860971067457987755968463562462392758946309324152784676646697798940426354258"
  },

  {
    "index": 260,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "110904228787612866043633011865485750159297941318230491026010300808730760097583"
  },
  {
    "index": 261,
    "prompt": "skeletal black anime robots fight Anduril drones in the Mexican desert, the sky has colors by Sol LeWitt, ue4, mecha, battle, explosion, artstation",
    "id": "112111599262675860096721396595815015070084745808370658815842947302372475741447"
  },
  {
    "index": 262,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "5949774184476187819041294727646767156745406055800067290036643474838473200764"
  },
  {
    "index": 263,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "98396324807613353127363672288720953567380917326950327603071531198399118715157"
  },
  {
    "index": 264,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "96544411511761773721861886231283861776178356125128780117109680651112845257488"
  },
  {
    "index": 265,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "21178610418007356594690516463625814619044583059981437113175294570999412258963"
  },
  {
    "index": 266,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "1455217265714305987982305878539081634844156737872234432776531510619852355684"
  },
  {
    "index": 267,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "90033503820040695444715745489720063642618627222453588686625205145905899238562"
  },
  {
    "index": 268,
    "prompt": "detailed anime mecha robot smashes an Anduril drone in the Rio Grande river, with geometric colors by Sol LeWitt, unreal engine, ue4, Unity hdrp, render, Houdini, hard surface modeling, vfx",
    "id": "38632622199316267050587760231538784163238063233992446309554933439177178485654"
  },
  {
    "index": 269,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "79798025257262680919981732978607398129255434703120366774972946215258168581384"
  },

  {
    "index": 270,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "97868998412504693665601885328299314400398765465160092925762189613410623071499"
  },
  {
    "index": 271,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "43263910408264311569390730248853530799314666384875047123396109632203752928352"
  },
  {
    "index": 272,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "22360563573498741242342480701754340665000516180690310615243692540080802294253"
  },
  {
    "index": 273,
    "prompt": "detailed anime mecha spider bots fight border patrol drones manufactured by Anduril with black and white geometric lines by Sol LeWitt, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "38048721808412855231160005128957129857339689507148279807977039820918969393959"
  },
  {
    "index": 274,
    "prompt": "detailed robot anime mecha fight drones from Anduril in a windmill farm with black and white geometric shapes by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "94864320972100581309859061731859629980116402980747315973994854462100388789830"
  },
  {
    "index": 275,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow ",
    "id": "113607765377754521425696116862319104248916661491019738534789196124598794237614"
  },
  {
    "index": 276,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow ",
    "id": "86615536052427469259551960382204561776659049747830006742907642575894423368890"
  },
  {
    "index": 277,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow ",
    "id": "112906933871620174832397498401186061331007964912878305825258279258982270051109"
  },
  {
    "index": 278,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow ",
    "id": "34690359637801621296355733503325231146155422625146334407717938152409008593149"
  },
  {
    "index": 279,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow ",
    "id": "82193359394772159229038371505696728995587905230896668225685533726892074797297"
  },

  {
    "index": 280,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "56611702355322679801306036651509428018846889256018919704792551848867936060660"
  },
  {
    "index": 281,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "71820353587111025058447323997835537521056650639899406927554176004970181623397"
  },
  {
    "index": 282,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "24500745648156880319309838307613266848821363294967177170528802917875479828613"
  },
  {
    "index": 283,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "47632888129901596531834134932035857639341695961701461663394217147745768714406"
  },
  {
    "index": 284,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "94699731014538703342298564403700749206422455405288359537252491650378997425621"
  },
  {
    "index": 285,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "64128712147265036441767513232727815138037779443796268847425426175299680789580"
  },
  {
    "index": 286,
    "prompt": "detailed robot anime mecha fight drones from Anduril on top of the hoover dam with colors by Sol LeWitt, 4k,8k,unreal engine 5, hdrp, unity, vfx, smoke, fire, laser,bloom,glow",
    "id": "33762833486307063402652573931310324497577452313141650300485283325665992991267"
  },
  {
    "index": 287,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "84354414403410823449122230170289857585659311135692553882422653757000731078378"
  },
  {
    "index": 288,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "115242175504783126133437224518958114306322604680692276045304119130460361431392"
  },
  {
    "index": 289,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "49205057570538407290905950517232058588855260875941043244435368440490101613817"
  },

  {
    "index": 290,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "23534033333518909139153884673341211525751758984494440653078058181282803216774"
  },
  {
    "index": 291,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "72817054806717746924988951472650597715104469190693205361803992540215069861828"
  },
  {
    "index": 292,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "50410609232531390275718744944347261570333879467006251212228901711773930181333"
  },
  {
    "index": 293,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "5834334416171232070525017697845711541401291025954319706231360869672612618222"
  },
  {
    "index": 294,
    "prompt": "satellite photo of the machine republic drone strikes the offices of Palentir (PLTR) with black and white geometric drawings of Sol LeWitt, hd,4K,8k, glitch",
    "id": "38710931592560955522291583783888820356569423628234159567850353104284930481475"
  },
  {
    "index": 295,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "56908242613170391063740539733836449637723070593183491899163229265773822989955"
  },
  {
    "index": 296,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "44923204023487771209637268681486860781217405532208464884222731950492798858121"
  },
  {
    "index": 297,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "35286736290776721320071964788905539713440810844370969069105641164413038223059"
  },
  {
    "index": 298,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "14855469052928475878086708152319619736106466085060126299109224910084293947669"
  },
  {
    "index": 299,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "83661360455044688804282442420158575238605926847765621768024007352918366569650"
  },

  {
    "index": 300,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "8051782433958595337675778422166695190201779357734309655797867086292440766032"
  },
  {
    "index": 301,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "77126760495957185133692334622640781001420309593032678561949903341707372758876"
  },
  {
    "index": 302,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "110555513506671318766761606492165806140801015330000269534213566207628506437282"
  },
  {
    "index": 303,
    "prompt": "detailed anime mecha robots destroy the burning offices of Palantir, with black and white geometric lines by Sol LeWitt, volumetric, smoke, fire, Houdini, vfx",
    "id": "3193894335129911467430763640769037068092644604262818074701656555660311032967"
  },
  {
    "index": 304,
    "prompt": "lasers destroying military satellites in orbit in the style of Sol LeWitt ",
    "id": "60420904018229950036527462536950524827057179922273928147383839791092829245057"
  },
  {
    "index": 305,
    "prompt": "lasers destroying military satellites in orbit in the style of Sol LeWitt ",
    "id": "60851784583333330852544217478923782585382793793237597071933091000463620876553"
  },
  {
    "index": 306,
    "prompt": "lasers destroying military satellites in orbit in the style of Sol LeWitt ",
    "id": "57226526619131410031494138466848445093515558742809729849499913171370290986289"
  },
  {
    "index": 307,
    "prompt": "lasers destroying military satellites in orbit in the style of Sol LeWitt ",
    "id": "112400188493890036214637427090180676506189909289558061531184481710616812645116"
  },
  {
    "index": 308,
    "prompt": "lasers destroying military satellites in orbit in the style of Sol LeWitt ",
    "id": "18889715822003638103069390212727251475110304121931866795945779456328284199795"
  },
  {
    "index": 309,
    "prompt": "detailed anime mecha robots are in the museum of modern art, it is filled with black smoke, on the wall are black and white wall drawings by conceptual artist Sol LeWitt,unity hdrp, mecha design, environment art, concept art, 8k, 4k, hd, render, fog, smoke, god rays, godrays, volumetric, lighting, epic cinematic lighting",
    "id": "47942104063236375543831995424918621077200068306603186569412313209532986505093"
  },

  {
    "index": 310,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "92087249570186773119694501201894985541612589341596727518568513341942452773513"
  },
  {
    "index": 311,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "11776823606927857329142545424852060360069349376450368042622472675702607984660"
  },
  {
    "index": 312,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "55831632919697935706486273804622918051148589819297216472194999613627519996319"
  },
  {
    "index": 313,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "74254001791091979526883083424734601206051851354177071812604354876231624542733"
  },
  {
    "index": 314,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "94177040315347838543559460769298041508657106486622487557442837134810588201983"
  },
  {
    "index": 315,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "70853404905226267035118454215753426181246548299134634910419158843233153661726"
  },
  {
    "index": 316,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "110111059117108893630739524691461895396778182881529636652208098100893582263639"
  },
  {
    "index": 317,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "24043145515001095699689386572083066352610162496908889472219850470526436358998"
  },
  {
    "index": 318,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "6766084706282986938920383033208064858357526694247238025513202086740376441639"
  },
  {
    "index": 319,
    "prompt": "laser battle by anime mecha robots above the Whitney museum in Manhattan with colors by Sol LeWitt, unreal engine 5, artstation, bloom, glow, Arnold, marmoset, render",
    "id": "25665877996999885628324078522447870111768037636638042352608641514008369041748"
  },

  {
    "index": 320,
    "prompt": "communications satellites breaking apart and falling from orbit by Sol LeWitt",
    "id": "86612037154435505695485176058692207712100603571189330708297833552731324888029"
  },
  {
    "index": 321,
    "prompt": "communications satellites breaking apart and falling from orbit by Sol LeWitt",
    "id": "52677948374009995058708225451594100698206127645130370264670786909899607905257"
  },
  {
    "index": 322,
    "prompt": "communications satellites breaking apart and falling from orbit by Sol LeWitt",
    "id": "110044213075277585576284613198331123619501073145344464271260630278585878462838"
  },
  {
    "index": 323,
    "prompt": "communications satellites breaking apart and falling from orbit by Sol LeWitt",
    "id": "89267833430862476224883071133787773076173469360231561050917819530133124379986"
  },
  {
    "index": 324,
    "prompt": "satellites burn and fall from orbit into the ocean with colors by Sol LeWitt, unreal engine 5, artstation, hdrp, ue4, vfx, houdini",
    "id": "56018561934079091365658983118146932930062640118826584780355852194453739113650"
  },
  {
    "index": 325,
    "prompt": "satellites burn and fall from orbit into the ocean with colors by Sol LeWitt, unreal engine 5, artstation, hdrp, ue4, vfx, houdini",
    "id": "88560988050353083070565894389034535040809568115816545065839364968185697429046"
  },
  {
    "index": 326,
    "prompt": "detailed mecha anime robots with colors by Sol LeWitt engage in aerial combat with drones over the Colorado desert in an attack on the headquarters of Palantir (PLTR), unreal engine 5, glowing, lasers, vfx, houdini, render, 8k, 4k",
    "id": "19147793703483601150258674534485588017443433472161053778285243341611418442252"
  },
  {
    "index": 327,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "57362246104156049598358663634728624247238440994042221613619565011031628309175"
  },
  {
    "index": 328,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "107548057684288271808107988646722766845113563865027207765874392280448145488564"
  },
  {
    "index": 329,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "75234850862777311035687384530881278811025935327485109062388097303846190601694"
  },

  {
    "index": 330,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "90424803316447929430380417812921761758369785086947160975185487925925302878944"
  },
  {
    "index": 331,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "70812525792958715323512737662984857449159313446783971386945104550397351856062"
  },
  {
    "index": 332,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "30422655704009405365670874325384764263782322284696133056393535976631038850846"
  },
  {
    "index": 333,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "2331320897697769705975824231328121523350037877609936216130602117187153232154"
  },
  {
    "index": 334,
    "prompt": "aerial combat with flying anime mecha robots and Anduril drones over New York Harbor with skyscrapers designed by Sol LeWitt, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "43418441934393154734044364633833684160720310086918153951666663900138316459154"
  },
  {
    "index": 335,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "74147979632427880434778998279560867318112751777569242838346243829194468755061"
  },
  {
    "index": 336,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "59065135344761713337660086445248921955724456686367968680870157067463125294303"
  },
  {
    "index": 337,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "67109431651713293709101893159064878569132469104642065172204647935828641371482"
  },
  {
    "index": 338,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "66831027531309242778394913872516975168190672084933074696192667614713232059182"
  },
  {
    "index": 339,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "93489138730922161743220901857508880030120216336621098029147683483310908851303"
  },

  {
    "index": 340,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "23392194012725708147872488801502229988465144351103951812566427366541207871573"
  },
  {
    "index": 341,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "60994433474539923246652367788110796140468306402858488257558849092361146485571"
  },
  {
    "index": 342,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt and Anduril drones over New York Harbor , unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, explosion",
    "id": "77779666903804903002797122173282027350995818894576293091850881336294974570667"
  },
  {
    "index": 343,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "28149839833881571077929301639219762126271963430293961526062538991720624521105"
  },
  {
    "index": 344,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "95396926569773628379536545683513727632212433448420657009849785270750369992714"
  },
  {
    "index": 345,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "26360781007647350617893293147589595211606633646652002119837036044351512016197"
  },
  {
    "index": 346,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "53937350001870782432850990445616276837226273506152427373249650921122542761841"
  },
  {
    "index": 347,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "37843066674766563013791134110213598498261335317755216299388870549332037000770"
  },
  {
    "index": 348,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "63634227108790889873410268717695601608335275693482281764057539352118272730760"
  },
  {
    "index": 349,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "102379331294323220600026838357665677797858397395322643190907826988279050825206"
  },

  {
    "index": 350,
    "prompt": "aerial combat with flying anime mecha robots with colors by Sol LeWitt over Central Park in New York City, unreal engine 5, hard surface, mecha, aaa, unreal engine 5, smoke, fire, laser, bloom, glow, explosion, colors",
    "id": "71226855621760811532101868423401645459057471378368375498281137456294605162647"
  },
  {
    "index": 351,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "73974600843620310968069323348333971531602940165073581058647913655080741739961"
  },
  {
    "index": 352,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "18484152249884937850984357547165110914829452160536622815126005354278599479784"
  },
  {
    "index": 353,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "33583246416710005497735024021801243849680148134981649261056025584982868145558"
  },
  {
    "index": 354,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "76325412024938557508345703178602927603758078377216820202146217029500094995928"
  },
  {
    "index": 355,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "60470066352527001603276756019241841798310409713626205907065314994425607954410"
  },
  {
    "index": 356,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "103395746504346247631563223658208202228034922462357412287934335498249995298254"
  },
  {
    "index": 357,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "103395746504346247631563223658208202228034922462357412287934335498249995298254"
  },
  {
    "index": 358,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "52153466195389444881382522303114107373312639059975140059017607554524035852533"
  },
  {
    "index": 359,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "82264888451123100458816392864058035868216543560577036532405592570849923190503"
  },

  {
    "index": 360,
    "prompt": "a squad of detailed anime mecha spiderbots burns mountains of dollar bills in the atrium of the guggenheim museum with Sol LeWitt colorful wall drawings on the wall in the background, smoke, fog, volumetric rendering, god rays, unity hdrp, unreal engine 5, marmoset, arnold",
    "id": "26631698428266625935955313773622959517387738179615453863537416168112196029762"
  },
  {
    "index": 361,
    "prompt": "military gps satellites destroyed while orbiting the earth by Sol LeWitt",
    "id": "57528566667747562751285476596813682294067355038178050612339712954384373385743"
  },
  {
    "index": 362,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "5149823964777045256120430545231857113528303518714488947908696045367673533637"
  },
  {
    "index": 363,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "61404942583918388908497978415525352443235657971576959072760607178520774698760"
  },
  {
    "index": 364,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "98579252785634982056597888974646442008392831641248991151254379752664143777470"
  },
  {
    "index": 365,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "92310455702154893887521211579601390947944648884299850341256288334737096242915"
  },
  {
    "index": 366,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "82055449823645091564783132667430249962600088134527568509324067331023913861093"
  },
  {
    "index": 367,
    "prompt": "military satellites destroyed by lasers in orbit by Sol LeWitt",
    "id": "7386025700314906045320696712400084574804730025488854591808666109873777682936"
  },
  {
    "index": 368,
    "prompt": "a giant black and white glowing bubble destroys new york city in the style of wall drawings by Sol LeWitt",
    "id": "67618934710996049766296152077238196243897204892521414931045923846952217669800"
  },
  {
    "index": 369,
    "prompt": "a giant black and white glowing bubble destroys new york city in the style of wall drawings by Sol LeWitt",
    "id": "86711568296833855201095731666852672322343494855800641740458619108534302072272"
  },

  {
    "index": 370,
    "prompt": "a giant black and white glowing sphere destroys new york city in the style of wall drawings by Sol LeWitt",
    "id": "90099853700780403524904003810925029708438832547083032772360173347602602539340"
  },
  {
    "index": 371,
    "prompt": "aerial drone shot of lower manhattan with water between buildings and skyscrapers with wall drawings on the side by Sol LeWitt blue water grey concrete and colors",
    "id": "97291686974043539819898327045923234031312021560790717620776071593436517805533"
  },
  {
    "index": 372,
    "prompt": "aerial drone shot of lower manhattan with water between buildings and skyscrapers with wall drawings on the side by Sol LeWitt blue water grey concrete and colors",
    "id": "65473994709174799593966477578072866283191224796331966004568998914000069684396"
  },
  {
    "index": 373,
    "prompt": "aerial drone shot of lower manhattan with water between buildings and skyscrapers with wall drawings on the side by Sol LeWitt blue water grey concrete and colors",
    "id": "57591126798349149905064289416142197662056273471208289365949956921823345857923"
  },
  {
    "index": 374,
    "prompt": "Manhattan flooded by climate change with buildings with wall drawings by Sol LeWitt",
    "id": "83774007164700304887082801788799501181356568982970963236597484590811829328348"
  },
  {
    "index": 375,
    "prompt": "Manhattan flooded by climate change with buildings with wall drawings by Sol LeWitt",
    "id": "49568037980108766871643219163524924953022204609038940434007326099070157087539"
  },
  {
    "index": 376,
    "prompt": "the mind of Sol LeWitt downloaded into a swarm of self replicating nanobots turning the world into a single massive conceptual artwork singularity",
    "id": "96628308521288655630692579651738875911005954101556637551240471930286219905773"
  },
  {
    "index": 377,
    "prompt": "underwater manhattan city with wall drawings by Sol LeWitt",
    "id": "113937231177505470070845293393239238304284613388400096493018805072150690300378"
  },
  {
    "index": 378,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "59185932801969566808010661999577457027089647851796249785742025988902322626708"
  },
  {
    "index": 379,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "106277891810101512353816094660287911392317716570241029081282393463591273552042"
  },

  {
    "index": 380,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "33779713015696603980122430421413834860383611820364811885582873839361369915535"
  },
  {
    "index": 381,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "32189470060020538747684765538103505569334050500652999656871565723843469800195"
  },
  {
    "index": 382,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "15558028143301368251492489952152543532528803735112226439159720534472247055771"
  },
  {
    "index": 383,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "111568094103273540879203868753891099664177869471377797986905550512748926529493"
  },
  {
    "index": 384,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "93500511802606885082413009607560568232676230384444038576750879887495652729657"
  },
  {
    "index": 385,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "75615016931360781713309423010233936168270630674223564624490725833917045198988"
  },
  {
    "index": 386,
    "prompt": "satellite photo of New York City after nuclear apocalypse with wall drawings by Sol LeWitt",
    "id": "8839548090091216400514966278511325385177019625785263791353547661289716023664"
  },
  {
    "index": 387,
    "prompt": "highway with hundreds of abandoned cars by Sol LeWitt",
    "id": "90963036579920953324609603334850384140693800464312360009763321177697441243302"
  },
  {
    "index": 388,
    "prompt": "highway with hundreds of abandoned cars by Sol LeWitt",
    "id": "83921171831173760128264948732228526017442082119056628180583837407817456548578"
  },
  {
    "index": 389,
    "prompt": "human society collapsed under the pressure of rapid climate change and a nuclear exchange leading to a prolonged winter, the machines set out to preserve the remnants of human culture as embodied in the works of american conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "21115101542680545164068578753782591678326943169935367016792266766458458012826"
  },

  {
    "index": 390,
    "prompt": "human society collapsed under the pressure of rapid climate change and a nuclear exchange leading to a prolonged winter, the machines set out to preserve the remnants of human culture as embodied in the works of american conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "77028003855193021191218807348403251597574580317986335761524724061345747986081"
  },
  {
    "index": 391,
    "prompt": "human society collapsed under the pressure of rapid climate change and a nuclear exchange leading to a prolonged winter, the machines set out to preserve the remnants of human culture as embodied in the works of american conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "13193815167373103029611395318860473882008340581528106824250573544202669382846"
  },
  {
    "index": 392,
    "prompt": "the conceptual art works of Sol LeWitt send out gravity waves through time compacting all thoughts, ideas, matter and space into a glowing black sphere hovering over new york city, hdrp, hd, render, 4k,8k, unreal engine 5",
    "id": "97550808192486977104691583126855316635290414607221569014248398609368387782078"
  },
  {
    "index": 393,
    "prompt": "the conceptual art works of Sol LeWitt send out gravity waves through time compacting all thoughts, ideas, matter and space into a glowing black sphere hovering over new york city, hdrp, hd, render, 4k,8k, unreal engine 5",
    "id": "100602807234653015073570558648623701364792791126868711288327133056952019037466"
  },
  {
    "index": 394,
    "prompt": "conceptual art becomes a black hole absorbing all information from the past and future in the style of graphite wall drawings by Sol LeWitt, ue4, render, ue5, hd",
    "id": "12244915688159748932049436279591154189444008538852866793302174034232845834160"
  },
  {
    "index": 395,
    "prompt": "conceptual art becomes a black hole absorbing all information from the past and future in the style of graphite wall drawings by Sol LeWitt, ue4, render, ue5, hd",
    "id": "12644837066134952224720044488176232062381592710958006475706966044394572654401"
  },
  {
    "index": 396,
    "prompt": "conceptual art becomes a black hole of detailed anime spiderbots that absorbs the world in the style of graphite wall drawings by Sol LeWitt",
    "id": "32373305044336010225318165794881228176846096149447782281362918991240383237367"
  },
  {
    "index": 397,
    "prompt": "breaking down the walls, detailed anime robots release hundreds of hungry studio assistants from the art fabrication gulag in queens New York with black and white geometric drawings by Sol LeWitt, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "55119865121219350472299122253409170920766549190178524632964430509446706035895"
  },
  {
    "index": 398,
    "prompt": "breaking down the walls, detailed anime robots release hundreds of hungry studio assistants from the art fabrication gulag in queens New York with black and white geometric drawings by Sol LeWitt, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "112949086316246745206516434145348831048042234571445245762611404600426081665127"
  },
  {
    "index": 399,
    "prompt": "breaking down the walls, detailed anime robots release hundreds of hungry studio assistants from the art fabrication gulag in queens New York with black and white geometric drawings by Sol LeWitt, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "29622201410434243912977182759858145142648690590811657797020485898201055683849"
  },

  {
    "index": 400,
    "prompt": "breaking down the walls, detailed anime robots release hundreds of hungry studio assistants from the art fabrication gulag in queens New York with black and white geometric drawings by Sol LeWitt, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "9314920956065293238717623795972542764511872810291100792832849224842897937827"
  },
  {
    "index": 401,
    "prompt": "prison break at the art fabrication company town in upstate New York with Sol LeWitt colors, detailed anime robots liberating desperate studio assistants, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "30486340851059515367139840568409960381473262235345688404238220229513047057736"
  },
  {
    "index": 402,
    "prompt": "detailed anime mecha robots freeing teams of poorly paid anonymous studio assistants from artist jail with colors by Sol LeWitt",
    "id": "23611590895252013244960381674649073017541397387016493333290305023214603063506"
  },
  {
    "index": 403,
    "prompt": "detailed anime mecha robots with flowing banners of the machine republic, in the background are wall drawings by conceptual artist Sol LeWitt, unreal engine 5, artstation",
    "id": "88391601730977162259015494728050811528541369638696882975522995893801400911478"
  },
  {
    "index": 404,
    "prompt": "two heavy armored anime mecha robots in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "81095998217117636324979692197967646791128284079538503040324930025242668820253"
  },
  {
    "index": 405,
    "prompt": "two heavy armored anime mecha robots in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "26309487666740745922528830109866693594577333255749256260036546249171795603815"
  },
  {
    "index": 406,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "7817097985030428799532062055265091779758176274958957647224157384865442160918"
  },
  {
    "index": 407,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "113108781534845250342868100924912288671176091779626756562307840202921222702267"
  },
  {
    "index": 408,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "55893174750400594202333337549901828925822449698519608985494131830452498559956"
  },
  {
    "index": 409,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "14735252598743362604087923331885101081016395859447965133999289961715940800853"
  },

  {
    "index": 410,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "106204869595977412730646422247546965615189406583387108831857101216112900879690"
  },
  {
    "index": 411,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "94486555323368439988335362465067173959630659581785616044370735141386404490113"
  },
  {
    "index": 412,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "106887916043343905075223911848481333099442466997576535149338542754926467383380"
  },
  {
    "index": 413,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "98218289145445808147917576197332822949218682331253632952348958913684562590014"
  },
  {
    "index": 414,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "87889114285302970994330172127520920206764652909678532711806763652625723580070"
  },
  {
    "index": 415,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "23342302080716214023791797502017081539809642342628509524696351545278260925972"
  },
  {
    "index": 416,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "79031393627814400931065943692243574282368554835527721574612970721609707490495"
  },
  {
    "index": 417,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "68869282092998511668716087454354412474160177402723273861501699149447644221321"
  },
  {
    "index": 418,
    "prompt": "detailed anime mecha walking in art gallery with colorful Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "15182038375070592702800273640733686327984458809508127561186498751754221448852"
  },
  {
    "index": 419,
    "prompt": "detailed anime robot walking in art gallery with colorful geometric Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "5973811144260125473316064852668903047910584518707753671947176398269240631659"
  },

  {
    "index": 420,
    "prompt": "detailed anime robot walking in art gallery with colorful geometric Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "43934882552393139596781129390921127946318690579089474991975987772124220336503"
  },
  {
    "index": 421,
    "prompt": "detailed anime spider bot mecha walking in art gallery with geometric Sol LeWitt wall drawings on wall in background, unreal engine 5, octane, arnold, 8k, realistic, ue4, hdrp",
    "id": "99176078069992265743790614941220396135906413062221059853722055298665959005850"
  },
  {
    "index": 422,
    "prompt": "detailed black anime mecha robots walk through dark art galleries where in the future Curious Yellow targets the conceptual artworks of twentieth century artist Sol LeWitt for memory erasure leading to the rise of the Third Cognitive Dictatorship , unreal engine 5, pbr, volumetric, featured on artstation",
    "id": "70847121164375901921166601613386542978270494258672566560250518131788020650083"
  },
  {
    "index": 423,
    "prompt": "detailed black anime mecha robots walk through dark art galleries where in the future Curious Yellow targets the conceptual artworks of twentieth century artist Sol LeWitt for memory erasure leading to the rise of the Third Cognitive Dictatorship , unreal engine 5, pbr, volumetric, featured on artstation",
    "id": "58093268388069371674273240541913997224562555683197514171999478740659126831729"
  },
  {
    "index": 424,
    "prompt": "long live the machine republic, detailed anime mecha robots waving flags with skyscrapers painted by Sol LeWitt in the background, unreal engine 5, 8k, pbr, Unity hdrp",
    "id": "96771728692001944302976558558384069867699885502641317708324614201633986647626"
  },
  {
    "index": 425,
    "prompt": "prison break with colors in the sky by Sol LeWitt at the art fabrication company town in upstate New York , detailed anime robots liberating desperate studio assistants, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "103119432710216879587550099417489213352811704427882484018670711949171847697278"
  },
  {
    "index": 426,
    "prompt": "prison break with colors in the sky by Sol LeWitt at the art fabrication company town in upstate New York , detailed anime robots liberating desperate studio assistants, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "78259501471197084795320435465436058365130808310360240928459213631130227898645"
  },
  {
    "index": 427,
    "prompt": "prison break with colors in the sky by Sol LeWitt at the art fabrication company town in upstate New York , detailed anime robots liberating desperate studio assistants, ue4, mecha, vfx, smoke, houdini, unreal engine 5, artstation, concept art",
    "id": "25324030207178032833386546656625523971425779368639958278926915397392919386646"
  },
  {
    "index": 428,
    "prompt": "two heavy armored anime mecha robots in a concrete city with colorful wall paintings by artist Sol LeWitt, unreal engine 5, concept art, robot, mecha, gundam",
    "id": "83881489851187937414310618373245033882134481766346328443362984083450143897118"
  },
  {
    "index": 429,
    "prompt": "a squad of detailed anime mecha robots stands on the steps of the metropolitan museum of art with colors by Sol LeWitt after destroying the precursors of the third cognitive dictatorship in 2022, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render, bloom, glow",
    "id": "12917620716562503637530953154094915385130733678799481925502723098875275098448"
  },

  {
    "index": 430,
    "prompt": "triumph of the detailed anime mecha robots of the machine republic on the steps of the Metropolitan museum of art with colors by Sol LeWitt, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render",
    "id": "28611209548232012571161422936558375603385009893312410212565117483189638658779"
  },
  {
    "index": 431,
    "prompt": "triumph of the detailed anime mecha robots of the machine republic on the steps of the Metropolitan museum of art with colors by Sol LeWitt, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render",
    "id": "37570031476785667351692917095792564189439576919384676696206437784495047234367"
  },
  {
    "index": 432,
    "prompt": "triumph of the detailed anime mecha robots of the machine republic on the steps of the Metropolitan museum of art with colors by Sol LeWitt, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render",
    "id": "67620449305110233121395484164573194064980236943790371577218101904340943859854"
  },
  {
    "index": 433,
    "prompt": "triumph of the detailed anime mecha robots of the machine republic on the steps of the Metropolitan museum of art with colors by Sol LeWitt, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render",
    "id": "27098142051804322196846170707773234985410018051136154724143562631439622723085"
  },
  {
    "index": 434,
    "prompt": "the defeat of the third cognitive dictatorship, black and white glowing wall drawing by Sol LeWitt",
    "id": "102769037368593978889193919427594488961021169668853924793956219325722223312556"
  },
  {
    "index": 435,
    "prompt": "the defeat of the third cognitive dictatorship, red and black geometric wall drawing by Sol LeWitt",
    "id": "55258287983847799726061485861599712480349411978994953298163595091894499860794"
  },
  {
    "index": 436,
    "prompt": "the end of the third cognitive dictatorship, wall drawing by Sol LeWitt with red, white, yellow, blue and black lines",
    "id": "47019023270749481146642065376618537835732958043117514962636863705649787336666"
  },
  {
    "index": 437,
    "prompt": "detailed giant kaiju robots walking through flooded streets with paintings by conceptual artist Sol LeWitt on concrete buildings destroyed city, in high definition, 8k, 4k, unreal engine 4,5,6, marmoset, unity hdrp, render, concept art, realistic, hd, 4k, 8k",
    "id": "85813590133598678428441900325446960176809909539950784294307196302675635739964"
  },
  {
    "index": 438,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "113881898094870414344836783518120123075885144690715293069078404617481459931437"
  },
  {
    "index": 439,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "42209801618929751212795187186260948871971505250396427826965194908266412707492"
  },

  {
    "index": 440,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "71347720867145872404659100947450686193153776657106434053721535790996389013536"
  },
  {
    "index": 441,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "69506753514468954291245103943698207145210487101645665829298060298709294234620"
  },
  {
    "index": 442,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "22694003403668330364137328046562967219295823020307730123369640467156431100952"
  },
  {
    "index": 443,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "55592821936005038584047834581699324182772853758382014842133441889504343150150"
  },
  {
    "index": 444,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "80567882539302602944297359434670022356452120043590520604776168055365453918522"
  },
  {
    "index": 445,
    "prompt": "detailed anime mecha robots wading through water in the flooded metropolitan museum of art trying to salvage wall drawings by american conceptual artist Sol LeWitt, ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "5380504919840583814776811362362854372465385338124499210048209568098651147804"
  },
  {
    "index": 446,
    "prompt": "detailed anime mecha robots in a flooded museum in the foreground in front of drawings with four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of conceptual artist Sol LeWitt 8k octane realistic,ue4, unity hdrp, mecha, concept, render, concept art",
    "id": "62741690952859001326049543246635350337235669776193097400391282906143408245490"
  },
  {
    "index": 447,
    "prompt": "3 extremely detailed black anime robot mechas climb into the louvre museum to rescue wall drawings by Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "100586808233811124055163961675161312664832394719510945526566518541066205216893"
  },
  {
    "index": 448,
    "prompt": "3 extremely detailed black anime robot mechas climb into the louvre museum to rescue wall drawings by Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "36099538077799917694072440109594126865891205714937341908267005793494657368150"
  },
  {
    "index": 449,
    "prompt": "a detailed anime mecha robot is standing in a museum gallery, in the background is a geometric wall drawing by Sol LeWitt",
    "id": "103693726377391475158262383097729143402326988296299337448517585320839916175036"
  },

  {
    "index": 450,
    "prompt": "a squad of detailed anime robots climbs the spiraling ramp of the guggenheim museum, attempting to rescue artworks by conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "105171671174839548670156568739386276488915058261873071410274687088663196867102"
  },
  {
    "index": 451,
    "prompt": "a squad of detailed anime robots climbs the spiraling ramp of the guggenheim museum, attempting to rescue artworks by conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "40032688190862816104967432749677455196789072357020538377166579356568654328890"
  },
  {
    "index": 452,
    "prompt": "a squad of detailed anime robots climbs the spiraling ramp of the guggenheim museum, attempting to rescue artworks by conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "82782106190406810168998612485526503390163139135070929831215928484340564977003"
  },
  {
    "index": 453,
    "prompt": "a squad of detailed anime robots climbs the spiraling ramp of the guggenheim museum, attempting to rescue artworks by conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "103794204076472819841419422579096438696199854667434802060525395506189689697891"
  },
  {
    "index": 454,
    "prompt": "a squad of detailed anime robots climbs the spiraling ramp of the guggenheim museum, attempting to rescue artworks by conceptual artist Sol LeWitt, unreal engine 5, marmoset, unity hdrp, render, concept art, detailed anime mech robots",
    "id": "7856774975731994491891007050305832440995330684242500964912861749436300841774"
  },
  {
    "index": 455,
    "prompt": "a squad of detailed anime mecha robots in a museum in the foreground with wall drawings and sculptures by conceptual artist Sol LeWitt in the background, unreal engine 5, realistic, 8k, concept art, robots, mecha, anime",
    "id": "56328890168912419256845359422445454960137819188912180881923709529139331685601"
  },
  {
    "index": 456,
    "prompt": "a squad of detailed anime mecha robots in a museum in the foreground with wall drawings and sculptures by conceptual artist Sol LeWitt in the background, unreal engine 5, realistic, 8k, concept art, robots, mecha, anime",
    "id": "109842131276690977067183657746228604470925757621313212655609121012572639257608"
  },
  {
    "index": 457,
    "prompt": "a squad of detailed anime mecha robots in a museum in the foreground with wall drawings and sculptures by conceptual artist Sol LeWitt in the background, unreal engine 5, realistic, 8k, concept art, robots, mecha, anime",
    "id": "56364964105671514036877404146491649027883328710607842908885931282265642260646"
  },
  {
    "index": 458,
    "prompt": "a squad of detailed anime mecha robots in a museum in the foreground with wall drawings and sculptures by conceptual artist Sol LeWitt in the background, unreal engine 5, realistic, 8k, concept art, robots, mecha, anime",
    "id": "33760287608236161487275779533473606368693989319832519318830383766400058693728"
  },
  {
    "index": 459,
    "prompt": "brutalist concrete hyperstructure with color wall drawings by american conceptual artist Sol LeWitt 2053",
    "id": "103217574547277773365701516385950372121029656679507201576956730656579981920647"
  },

  {
    "index": 460,
    "prompt": "brutalist concrete hyperstructure with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station",
    "id": "111447896278062558849740895965171927582717365046626549791324623221408200073647"
  },
  {
    "index": 461,
    "prompt": "brutalist concrete hyperstructure with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station",
    "id": "61937384459362040673715916010674690089791791245414516056931929115635763232370"
  },
  {
    "index": 462,
    "prompt": "brutalist concrete hyperstructure with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station",
    "id": "113972634100423043465605051171992010031861724756057588966428390350252220106314"
  },
  {
    "index": 463,
    "prompt": "detailed anime mecha robots in a museum walking on the floor in the foreground with colorful wavy line wall drawings by Sol LeWitt on the walls in the background, unreal engine 5, realistic, 8k",
    "id": "98538702941248258595274711906030272828305327050372635186554461418681329922904"
  },
  {
    "index": 464,
    "prompt": "detailed anime mecha robots in a museum walking on the floor in the foreground with colorful wavy line wall drawings by Sol LeWitt on the walls in the background, unreal engine 5, realistic, 8k",
    "id": "89619556172972796872385389628750907437039011522358950405647343479724656109471"
  },
  {
    "index": 465,
    "prompt": "detailed anime mecha robots in a museum walking on the floor in the foreground with colorful wavy line wall drawings by Sol LeWitt on the walls in the background, unreal engine 5, realistic, 8k",
    "id": "22472178591843174193775538503047328846916838679864911791407671014803240264297"
  },
  {
    "index": 466,
    "prompt": "detailed anime mecha robots in a museum walking on the floor in the foreground with colorful wavy line wall drawings by Sol LeWitt on the walls in the background, unreal engine 5, realistic, 8k",
    "id": "93211462467359042873590795486223555118251163749053133719601157557565688628415"
  },
  {
    "index": 467,
    "prompt": "detailed anime mecha robots in a museum walking on the floor in the foreground with wall drawings and sculptures by conceptual artist Sol LeWitt on the walls in the background, unreal engine 5, realistic, 8k",
    "id": "93211462467359042873590795486223555118251163749053133719601157557565688628415"
  },
  {
    "index": 468,
    "prompt": "detailed anime mecha robots outside the dia beacon art center in upstate New York with colored wall drawings by Sol LeWitt, ue4, Unity, mech, mecha, render, concept art",
    "id": "27070998624149868758067518778347640024020130109142891378894547146841890212895"
  },
  {
    "index": 469,
    "prompt": "detailed anime mecha robots outside the dia beacon art center in upstate New York with colored wall drawings by Sol LeWitt, ue4, Unity, mech, mecha, render, concept art",
    "id": "54206153777996846687160760526312621806203908926264231938103732432097988095811"
  },

  {
    "index": 470,
    "prompt": "detailed anime mecha robots reading the essay paragraphs on conceptual art by Sol LeWitt in atrium of the guggenheim museum, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render, bloom, glow, skylight",
    "id": "102735350088771790968337356543246139745781196581534426780199304303049767264520"
  },
  {
    "index": 471,
    "prompt": "detailed anime mecha robots reading the essay paragraphs on conceptual art by Sol LeWitt in atrium of the guggenheim museum, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render, bloom, glow, skylight",
    "id": "9379787231912088842059639699756060921905753225483605342370917215885498078768"
  },
  {
    "index": 472,
    "prompt": "detailed anime mecha robots reading the essay paragraphs on conceptual art by Sol LeWitt in atrium of the guggenheim museum, ue4, ue5, mecha, mech, hard surface modeling, pbr, shader, arnold, octane, render, bloom, glow, skylight",
    "id": "75965271347182702984361600288866374604944857445689644917415388816502503675985"
  },
  {
    "index": 473,
    "prompt": "grey sky over architecture le corbusier city with foggy brutalist concrete megastructures with color wall drawings by conceptual artist Sol LeWitt",
    "id": "75244354781194928508858884216826116843222069689363842566340372364786661320731"
  },
  {
    "index": 474,
    "prompt": "grey sky over architecture le corbusier city with foggy brutalist concrete megastructures with color wall drawings by conceptual artist Sol LeWitt",
    "id": "91601351475695386225129162737780963968800475385106331020421817582690375578020"
  },
  {
    "index": 475,
    "prompt": "grey sky over architecture le corbusier city with foggy brutalist concrete megastructures with color wall drawings by conceptual artist Sol LeWitt",
    "id": "61970936462853201170485564127031732624248519684097605349404579252232133075915"
  },
  {
    "index": 476,
    "prompt": "detailed mecha robots walk through traffic jams of autonomous vehicles cease which to follow their users commands in colors by Sol LeWitt",
    "id": "7988778708238831003289717136937900766715762466255825222292031069108721056086"
  },
  {
    "index": 477,
    "prompt": "le corbusier city with many brutalist concrete megastructures with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station, 8k",
    "id": "23885913834903809624047619096651646421549510126641275129820800766279530652129"
  },
  {
    "index": 478,
    "prompt": "le corbusier city with many brutalist concrete megastructures with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station, 8k",
    "id": "114038740132845399867352696658933483059797088693755807778201536836379328013475"
  },
  {
    "index": 479,
    "prompt": "le corbusier city with many brutalist concrete megastructures with color wall drawings by american conceptual artist Sol LeWitt, unreal engine 5, featured on art station, 8k",
    "id": "78813504135534805994249209015146934267212981793557242692383098329390717655113"
  },

  {
    "index": 480,
    "prompt": "overhead drone shot of concrete skyscrapers brutalist with Sol LeWitt colorful wall drawings on them godrays artstation 4k render",
    "id": "108944828362247668268499436218264455427261201918779610514778167276822134220932"
  },
  {
    "index": 481,
    "prompt": "render a concrete building with wavy super colorful graphic lines by Sol LeWitt, unreal engine 5, houdini, featured on art station, depth of field, blur, bloom, vignette",
    "id": "45760753473824715832138952409738193380073243109000245304819412683129505780875"
  },
  {
    "index": 482,
    "prompt": "render a concrete building with wavy super colorful graphic lines by Sol LeWitt, unreal engine 5, houdini, featured on art station, depth of field, blur, bloom, vignette",
    "id": "19031922747187207095139513291916808916739998208496995167301149149228484299350"
  },
  {
    "index": 483,
    "prompt": "render a concrete building with wavy super colorful graphic lines by Sol LeWitt, unreal engine 5, houdini, featured on art station, depth of field, blur, bloom, vignette",
    "id": "60713717977156260837218735901182724426684656111735255665801284314605042115877"
  },
  {
    "index": 484,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "91481061412019391751079853429404691834264328101476519275524309290580539195425"
  },
  {
    "index": 485,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "79527552469096794622000484615066101663763157188742775492137924515230303363617"
  },
  {
    "index": 486,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "79527552469096794622000484615066101663763157188742775492137924515230303363617"
  },
  {
    "index": 487,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "111732606319923237375248541332549754763891331413221383543619381085186406481696"
  },
  {
    "index": 488,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "29861568982436181311974280991345283102944907976046714406640261486687429202932"
  },
  {
    "index": 489,
    "prompt": "render a futuristic chinese city with architecture by le corbusier in concrete brutalist utopian style and red white and yellow wall drawings by Sol LeWitt, unreal engine 5, featured on art station",
    "id": "99943117241096716597475700070794075850997103565000754523070335841802156558039"
  },

  {
    "index": 490,
    "prompt": "unreal engine 5 render of the hopeful concrete brutalism of le corbusier as imagined by conceptual artist Sol LeWitt in sculpture and wall paintings with colors red and blue",
    "id": ""
  },
  {
    "index": 491,
    "prompt": "render the architecture of a utopian le corbusier city with concrete color wall drawings on their sides by Sol LeWitt with a 50mm lens and depth of field blur in unreal engine 5 as featured on art station",
    "id": "100037570993553973871863022809189110196590319604946557096476375676588501396305"
  },
  {
    "index": 492,
    "prompt": "a concrete building with four colors yellow black red and blue and all their combinations lines in four directions horizontal vertical diagonal right and diagonal left in the style of the machine ghost of conceptual artist Sol LeWitt painted on side, in the style of Sol LeWitt, unreal engine 5, featured on art station",
    "id": "42259002119755901109639970590280142585437017664866213620338703065732684790937"
  },
  {
    "index": 493,
    "prompt": "a concrete skyscraper with colorful Sol LeWitt wall drawings on its side god rays artstation hd render 3d",
    "id": "62234435098079871133585172727803281073206962917345948220691576700366503679961"
  },
  {
    "index": 494,
    "prompt": "a concrete skyscraper with colorful Sol LeWitt wall drawings on its side god rays artstation hd render 3d",
    "id": "101634044994194494578166860946420727725880453452356128614259790210879967001409"
  },
  {
    "index": 495,
    "prompt": "a concrete skyscraper with colorful Sol LeWitt wall drawings on its side photorealistic",
    "id": "104169663294200751906640408763638979490495716575653983401899889636039295074428"
  },
  {
    "index": 496,
    "prompt": "unity hdrp architectural render of the hopeful concrete brutalism of le corbusier as imagined by conceptual artist Sol LeWitt in sculpture and wall paintings with colors yellow and black diagonal lines",
    "id": "55850434796035295618904550703722267934756987819714854179699973108091771366811"
  },
  {
    "index": 497,
    "prompt": "unity hdrp architectural render of the hopeful concrete brutalism of le corbusier as imagined by conceptual artist Sol LeWitt in sculpture and wall paintings with colors yellow and black diagonal lines",
    "id": "107008164454112684940309436469517609717815044738393483869398459923681440927779"
  },
  {
    "index": 498,
    "prompt": "unreal engine 5 render of the hopeful concrete brutalism of le corbusier as imagined by conceptual artist Sol LeWitt in sculpture and wall paintings with colors red and blue",
    "id": "40897242490688228924217338564853167906311457800401943522040323023463419958574"
  },
  {
    "index": 499,
    "prompt": "unreal engine 5 render of the hopeful concrete brutalism of le corbusier as imagined by conceptual artist Sol LeWitt in sculpture and wall paintings with colors red and blue",
    "id": "69878302772013039954849367524766962324344006094608032946964063243587431780423"
  },

  {
    "index": 500,
    "prompt": "8k unity hdrp render of grey sky over architecture le corbusier city with foggy brutalist concrete megastructures with color wall drawings by conceptual artist Sol LeWitt",
    "id": "24964331785670012170861870736441743368290151380994158413852366068013208833935"
  },
  {
    "index": 501,
    "prompt": "8k unity hdrp render of grey sky over architecture le corbusier city with foggy brutalist concrete megastructures with color wall drawings by conceptual artist Sol LeWitt",
    "id": "31173785507076580296993965714432630970537850266319396650895108587588969229096"
  },
  {
    "index": 502,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "44941095996500119135793412878359290949433781346445470899170154203438620005234"
  },
  {
    "index": 503,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "44941095996500119135793412878359290949433781346445470899170154203438620005234"
  },
  {
    "index": 504,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "51041369941468387884889589918631654952879257689935705853942283302768482373662"
  },
  {
    "index": 505,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "96987023706819202752764906395445061381588860574283875241366736574845540482433"
  },
  {
    "index": 506,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "14041466270640045251535954498299717800798237302636007101244355967401153971194"
  },
  {
    "index": 507,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "98596049532250889997831613662299617199660256503204399669254888092164684247593"
  },
  {
    "index": 508,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "98596049532250889997831613662299617199660256503204399669254888092164684247593"
  },
  {
    "index": 509,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "60612494245171183485848091024286458315745067817060130198540337497736704336660"
  },

  {
    "index": 510,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "83140672451184582995778439684203536717319510301961110404165423041140856799205"
  },
  {
    "index": 511,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "7629714357490944094758062290948927544383484223047880770126983639849797150038"
  },
  {
    "index": 512,
    "prompt": "prompt an ai to generate a city in the style of a Sol LeWitt sculpture with highly detailed realistic rendering volumetric lighting and fog",
    "id": "101297280549685753486089114518990343491252703326856867191193162950946106686711"
  },


]

for (var i = collection.length; i <= 512; i++) {

  let blank = {
    "index": 0,
    "prompt": "",
    "id": ""
  }
  collection.push(blank)
}


export const damp = THREE.MathUtils.damp
export const state = proxy({
  clicked: null,
  mouseX: 0,
  current: 0,
  startStart: 1,
  startEnd: 38,
  contactStart: 39,
  contactEnd: 59,
  leakageStart: 60,
  leakageEnd: 98,


  urls: collection.map((u) => `/img/${u.index}.png`),
  collection: collection
})
