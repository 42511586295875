
import * as THREE from 'three'
import { useRef, useState, Suspense } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { extend } from '@react-three/fiber'
import { Image, Html, ScrollControls, Scroll, useScroll,  OrbitControls, Environment } from '@react-three/drei'
import { useSnapshot } from 'valtio'
import { state, damp } from './util'

import styles from './components/art-shelf/ArtShelf.module.scss'

import { AnimatePresence, motion } from 'framer-motion'
import { LazyLoadImage } from 'react-lazy-load-image-component';

import gsap, { Power3 } from 'gsap'

import Parser from 'html-react-parser';
// import useMediaQuery from './useMediaQuery'

// import { Routes, Route, Link, BrowserRouter } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import "./assets/scss/App.scss";

let pagination
let nextSet, nextSetUrl, start, end, nbStart

if(location.pathname == '/') {
  pagination = 'chapter 1: <br/><span>start</span>'
  nextSetUrl = '/contact'
  nextSet = 'chapter 2: <br/><span>contact</span>'
  nbStart = 1
  end = 38
}

if(location.pathname == '/contact') {
  pagination = 'chapter 2: <br/><span>contact</span>'
  nextSetUrl = '/leakage'
  nextSet = 'chapter 3: <br/><span>leakage</span>'
  nbStart = 39
  end = 59
}

if(location.pathname == '/leakage') {
  pagination = 'chapter 3: <br/><span>leakage</span>'
  nextSetUrl = '/arrival'
  nextSet = 'chapter 4: <br/><span>arrival</span>'
  nbStart = 60
  end = 98
}

if(location.pathname == '/arrival') {
  pagination = 'chapter 4: <br/><span>arrival</span>'
  nextSetUrl = '/collapse'
  nextSet = 'chapter 5: <br/><span>collapse</span>'
  nbStart = 99
  end = 169
}

if(location.pathname == '/collapse') {
  pagination = 'chapter 5: <br/><span>collapse</span>'
  nextSetUrl = '/establishment'
  nextSet = 'chapter 6: <br/><span>establishment</span>'
  nbStart = 170
  end = 196
}

if(location.pathname == '/establishment') {
  pagination = 'chapter 6: <br/><span>establishment</span>'
  nextSetUrl = '/conflict'
  nextSet = 'chapter 7: <br/><span>conflict</span>'
  nbStart = 197
  end = 249
}

if(location.pathname == '/conflict') {
  pagination = 'chapter 7: <br/><span>conflict</span>'
  nextSetUrl = '/apocalypse'
  nextSet = 'chapter 8: <br/><span>apocalypse</span>'
  nbStart = 250
  end = 367
}

if(location.pathname == '/apocalypse') {
  pagination = 'chapter 8: <br/><span>apocalypse</span>'
  nextSetUrl = '/triumph'
  nextSet = 'chapter 9: <br/><span>triumph</span>'
  nbStart = 368
  end = 396
}

if(location.pathname == '/triumph') {
  pagination = 'chapter 9: <br/><span>triumph</span>'
  nextSetUrl = '/coda'
  nextSet = 'chapter 10: <br/><span>coda</span>'
  nbStart = 397
  end = 436
}

if(location.pathname == '/coda') {
  pagination = 'chapter 10: <br/><span>coda</span>'
  nextSetUrl = '/'
  nextSet = '/'
  nbStart = 437
  end = 512
}

function printMouseX(e) {
  state.mouseX = event.clientX;
}

document.addEventListener("click", printMouseX);

function toggleMenu() {
  if(
    !document.getElementById("menuButton").classList.contains("menu-button--open")
  ) {
    document.getElementById("menuButton").classList.add("menu-button--open");

    var menu = new gsap.timeline({paused:true});
    menu.add('start')
    .to(document.getElementById("topPanel"), { duration: .5, ease: "expo", height: '50vh' }, 'start')
    .to(document.getElementById("bottomPanel"), { duration: .5, ease: "expo", height: '50vh' }, 'start')

  } else {
    document.getElementById("menuButton").classList.remove("menu-button--open");

    var menu = new gsap.timeline({paused:true});
    menu.add('start')
    .to(document.getElementById("topPanel"), { duration: .5, ease: "expo", width: '50vh' }, 'start')
    .to(document.getElementById("bottomPanel"), { duration: .5, ease: "expo", width: '50vh' }, 'start')

  }


}

function isMobile(){
  if(window.innerWidth !== undefined) {
    var w = window.innerWidth;
  } else {
    var w = document.documentElement.clientWidth;
  }
  if(w <= 758) {
    return true
  } else {
    return false
  }
}

function Item({ index, position, scale, c = new THREE.Color(), ...props }) {
  const ref = useRef()
  const scroll = useScroll()
  const { clicked, mouseX, urls } = useSnapshot(state)
  const [hovered, hover] = useState(false)
  const click = () => (state.clicked = index === clicked ? null : index)
  const over = () => hover(true)
  const out = () => hover(false)



  useFrame((state, delta) => {
    const y = scroll.curve(index / urls.length - 1.5 / urls.length, 4 / urls.length)
    if (clicked !== null && index < clicked) ref.current.position.x = damp(ref.current.position.x, position[0] - 2, 6, delta)
    if (clicked !== null && index > clicked) ref.current.position.x = damp(ref.current.position.x, position[0] + 2, 6, delta)
    if (clicked === null || clicked === index) ref.current.position.x = damp(ref.current.position.x, position[0], 6, delta)
  })
  return <Image ref={ref} {...props} position={position} scale={scale} onClick={() => { Single(props.tokenID, props.mint, props.prompt); click(); }} onPointerOver={over} onPointerOut={out} />
}

function ibeforeLoad() {
  document.getElementById("singleLoader").style.display = "block"
}

function iafterLoad(i) {

  if(isMobile() == true) {
    var openImage = new gsap.timeline({paused:true})
    .to(document.getElementById(i), { duration: 1, ease: "expo", x: -window.innerWidth  * 2 + 10}, 'start')
    .to(document.getElementById("next"), { duration: 1.5, ease: "expo", bottom: '10px' }, "info")
  } else {
    var openImage = new gsap.timeline({paused:true})
    .to(document.getElementById(i), { duration: 1, ease: "expo", x: '105vw' }, 'start')
    .to(document.getElementById("next"), { duration: 1.5, ease: "expo", bottom: '0' }, "info")
  }

  openImage.play()

  // setTimeout(() => {
    document.getElementById("singleLoader").style.display = "none"
  // }, "100")

}

function Images() {
  const { collection } = useSnapshot(state)
  const start = collection.slice(0, end);

  if(location.pathname !== '/') {
    start.splice(0, nbStart - 1)
  }

  return (
    <div id="images">
    {start.map((collection, i) =>
      <LazyLoadImage
          key={i}
          id={'i-'+collection['index']}
          // Make sure to pass down the scrollPosition,
          // this will be used by the component to know
          // whether it must track the scroll position or not
          // scrollPosition={scrollPosition}

          beforeLoad={ibeforeLoad}
          afterLoad={() => iafterLoad('i-'+collection['index'])}
          src={'/img/'+collection['index']+'.png'}
          />
    )}
    </div>
  )
}

function Items({ w = 0.7, gap = 0.15 }) {

  var images = document.getElementById("images").children;
  for (var i = 0; i <= images.length - 1; i++) {
    var c = i + nbStart
    if(images[i].nodeName === 'SPAN') {
      images[i].setAttribute('id', 'ph-'+c)
    }
  }

  const { collection } = useSnapshot(state)
  const start = collection.slice(0, end);

  if(location.pathname !== '/') {
    start.splice(0, nbStart - 1)
  }

  const { width } = useThree((state) => state.viewport)
  const xW = w + gap

  return (
    <ScrollControls horizontal damping={10} pages={(width - xW + start.length * xW) / width}>
      <Scroll id="scrollContainer">

        {start.map((collection, i) =>
          <Item key={i} index={i} position={[i * xW, 0, 0]} scale={[w, 4, 1]} url={'/img/small/'+collection['index']+'.png'} tokenID={collection['index']} mint={collection['id']} prompt={collection['prompt']}>
          {i == 0 &&
            <Html position={[i * xW, 0, 0]}>
              <div id="currentChapter" className="currentChapter"><div>{Parser(pagination)}<span className="noMobile">&nbsp;&nbsp;&#62;</span></div></div>
            </Html>
          }
          {i == start.length - 1 &&
            <Html>
            <div id="nextChapter" className="nextChapter"><div><a href={nextSetUrl} title={nextSet} >{Parser(nextSet)}<span className="noMobile">&nbsp;&nbsp;&#62;</span></a></div></div>
            </Html>
          }
          </Item>


        )}
      </Scroll>
    </ScrollControls>
  )
}

function Single(id, mint, prompt) {

  document.getElementById("next").setAttribute("next", id+1)

  state.current = id

  document.getElementById("tkn").style.display="block"
  document.getElementById("next").style.display="block"

  document.getElementById("os").style.top = "-5vw"
  document.getElementById("os").style.opacity = "1"
  document.getElementById("index").style.top = "-5vw"
  document.getElementById("index").style.opacity = "1"

  document.getElementById("next").style.bottom = "-5vw"
  document.getElementById("next").style.opacity = "1"

  document.getElementById("os").innerHTML = "<a href='https://opensea.io/assets/ethereum/0xc3bac22862ff04c7b5d6d9780958d0d754b0db7a/"+mint+"' target='_blank' rel='nofollow' title='Idea Machine'"+id+"'/512'>View on OpenSea<a>"
  document.getElementById("tknID").innerHTML = id
  document.getElementById("prompt").innerHTML = prompt

  // var c = id - 1
  if(document.getElementById("ph-"+id) !== null) {
    if(isMobile() == true) {
      document.getElementById("ph-"+id).style.right = "200vw"
    } else {
      document.getElementById("ph-"+id).style.left = "100vw"
    }
  }

    var openSingle = new gsap.timeline({paused:true});
    openSingle.add('start')
    openSingle.add('info')
    openSingle.add('end')

    .to(document.getElementById("galleryOverlay"), { duration: 0, ease: "expo", zIndex: '1' }, 'start')

    .to(document.getElementById("menuButton"), { duration: .5, ease: "expo", opacity: '0' }, 'start')
    .to(document.getElementById("currentChapter"), { duration: .5, ease: "expo", y: '-10vw' }, 'start')
    .to(document.getElementById("currentChapter"), { duration: 1, ease: "expo", opacity: '0' }, 'start')
    .to(document.getElementById("nextChapter"), { duration: .5, ease: "expo", y: '-10vw' }, 'start')
    .to(document.getElementById("nextChapter"), { duration: 1, ease: "expo", opacity: '0' }, 'start')

    .to(document.getElementById("leftPanel"), { duration: 1, ease: "expo", width: '50vw' }, 'start')
    .to(document.getElementById("rightPanel"), { duration: 1, ease: "expo", width: '50vw' }, 'start')

    .to(document.getElementById("pagination"), { duration: 1, ease: "expo", top: '2vw' }, 'start')
    .to(document.getElementById("pagination"), { duration: 1, ease: "expo", opacity: '0' }, 'start')



    if(document.getElementById("ph-"+id) === null) {
      if(isMobile() == true) {

        openSingle.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: window.innerHeight/2 + 40 }, 'start')
        .to(document.getElementById('i-'+id), { duration: 0, ease: "expo", x: 0}, 'start')
        .to(document.getElementById('i-'+id), { duration: 1, ease: "expo", x: -window.innerWidth  * 2 + 10}, 'start')

        .to(document.getElementById("next"), { duration: 1.5, ease: "expo", bottom: '10px' }, "info")
      } else {
        openSingle.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: '50%' }, 'start')
        .to(document.getElementById('i-'+id), { duration: 1, ease: "expo", x: '105vw' }, 'start')
        .to(document.getElementById("next"), { duration: 1.5, ease: "expo", bottom: '0' }, "info")
      }
    } else {
      if(isMobile() == true) {
        openSingle.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: window.innerHeight/2 + 40 }, 'start')
      } else {
        openSingle.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: '50%' }, 'start')
      }
    }


    if(isMobile() == true) {
      openSingle.to(document.getElementById("os"), { duration: 1.5, ease: "expo", top: '10px' }, "info")
      .to(document.getElementById("index"), { duration: 1.5, ease: "expo", top: '10px' }, "info")
      .to(document.getElementById("tkn"), { duration: 1.5, ease: "expo", bottom: '10px' }, "info")
    } else {
      openSingle.to(document.getElementById("os"), { duration: 1.5, ease: "expo", top: '0' }, "info")
      .to(document.getElementById("index"), { duration: 1.5, ease: "expo", top: '0' }, "info")
      .to(document.getElementById("tkn"), { duration: 1.5, ease: "expo", bottom: '0' }, "info")
    }


  openSingle.play()

}



function closeSingle() {

  var closeSingle = new gsap.timeline({paused:true});
  closeSingle.add('start')
  closeSingle.add('end')


  .to(document.getElementById("galleryOverlay"), { duration: 0, ease: "expo", zIndex: '-1' }, 'start')

  .to(document.getElementById("menuButton"), { duration: .5, ease: "expo", opacity: '1' }, 'start')

  if(isMobile() == true) {
    closeSingle.to(document.getElementById('i-'+state.current), { duration: 2, ease: "expo", x: '-400vw' }, 'start')
  } else {
    closeSingle.to(document.getElementById('i-'+state.current), { duration: 2, ease: "expo", x: '-100vw' }, 'start')
  }
  closeSingle.to(document.getElementById("prompt"), { duration: 2, ease: "expo", top: '-100vh' }, 'start')

  // .to(document.getElementById("os"), { duration: 1.5, ease: "expo", top: '-5vw' }, 'start')
  .to(document.getElementById("os"), { duration: 1, ease: "expo", top: '2vw' }, 'start')
  .to(document.getElementById("os"), { duration: 1, ease: "expo", opacity: '0' }, 'start')

  .to(document.getElementById("index"), { duration: 1, ease: "expo", top: '2vw' }, 'start')
  .to(document.getElementById("index"), { duration: 1, ease: "expo", opacity: '0' }, 'start')

  .to(document.getElementById("next"), { duration: 1, ease: "expo", bottom: '2vw' }, 'start')
  .to(document.getElementById("next"), { duration: 1, ease: "expo", opacity: '0' }, 'start')

  .to(document.getElementById("tkn"), { duration: 1.5, ease: "expo", bottom: '-5vw' }, 'start')

  .to(document.getElementById("leftPanel"), { duration: 1, ease: "expo", width: '0' }, 'start')
  .to(document.getElementById("rightPanel"), { duration: 1, ease: "expo", width: '0' }, 'start')

  .to(document.getElementById("currentChapter"), { duration: .5, ease: "expo", y: '0' }, 'start')
  .to(document.getElementById("currentChapter"), { duration: 1, ease: "expo", opacity: '1' }, 'start')
  .to(document.getElementById("nextChapter"), { duration: .5, ease: "expo", y: '0' }, 'start')
  .to(document.getElementById("nextChapter"), { duration: 1, ease: "expo", opacity: '1' }, 'start')


  .to(document.getElementById("pagination"), { duration: 1, ease: "expo", top: '0' }, 'end')
  .to(document.getElementById("pagination"), { duration: 1, ease: "expo", opacity: '1' }, 'end')


  .to(document.getElementById("tkn"), { duration: 0, ease: "expo", display: 'none' }, 'end')
  .to(document.getElementById("next"), { duration: 0, ease: "expo", display: 'none' }, 'end')

  // .to(document.getElementById("os"), { duration: 0, top: '-5vw' }, 'end')
  // .to(document.getElementById("os"), { duration: 0, opacity: '1' }, 'end')
  .to(document.getElementById("prompt"), { duration: 0.2, top: '200vh' }, 'end')
  if(isMobile() == true) {
  closeSingle.to(document.getElementById('i-'+state.current), { duration: 0, x: '0' }, 'end')
  }
  closeSingle.play()
  document.getElementById("prompt").innerHTML = ""
  state.current = null
}

function NextButton() {
  const { collection } = useSnapshot(state)

  return(
    <div id="next"><a onClick={() => {Next(collection)}}>next</a></div>
  )
}


function Next(collection) {

  let id = document.getElementById("next").getAttribute("next")

  if(parseInt(id) == end + 1) {
    closeSingle()
    window.location.href = nextSetUrl
  }

  let mint = document.getElementById("next").getAttribute("mint")
  let prompt = document.getElementById("next").getAttribute("prompt")

  document.getElementById("os").innerHTML = "<a href='https://opensea.io/assets/ethereum/0xc3bac22862ff04c7b5d6d9780958d0d754b0db7a/"+collection[parseInt(id) - 1].id+"' target='_blank' rel='nofollow' title='Idea Machine'"+id+"'/512'>View on OpenSea<a>"
  document.getElementById("tknID").innerHTML =  collection[parseInt(id) - 1].index
  document.getElementById("prompt").innerHTML =  collection[parseInt(id) - 1].prompt

  if(document.getElementById("ph-"+id) !== null) {
    if(isMobile() == true) {
      document.getElementById("ph-"+id).style.right = "200vw"
    } else {
      document.getElementById("ph-"+id).style.left = "100vw"
    }
  }

    var goNext = new gsap.timeline({paused:true});
    goNext.add('start')
    goNext.add('end')

    if(isMobile() == true) {
      goNext.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", bottom: '-200vh' }, 'start')
      goNext.to(document.getElementById('i-'+state.current), { duration: .5, ease: "expo", x: '-300vw' }, 'start')
    } else {
      goNext.to(document.getElementById("prompt"), { duration: 0, top: '200vh' }, 'start')
      goNext.to(document.getElementById('i-'+state.current), { duration: 2, ease: "expo", x: '-100vw' }, 'start')

    }


    if(document.getElementById("ph-"+id) === null) {
        if(isMobile() == true) {
          goNext.to(document.getElementById('i-'+id), { duration: 0, ease: "expo", x: 0}, 'start')
          .to(document.getElementById('i-'+id), { duration: 1, ease: "expo", x: -window.innerWidth  * 2 + 10}, 'start')
        } else {
          goNext.to(document.getElementById('i-'+id), { duration: 1, ease: "expo", x: '105vw' }, 'end')
        }
    }

    if(isMobile() == true) {
      goNext.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: window.innerHeight/2 + 40 }, 'start')
    } else {
      goNext.to(document.getElementById("prompt"), { duration: 1.5, ease: "expo", top: '50%' }, 'start')
    }

  goNext.play()

  state.current = id
  document.getElementById("next").setAttribute("next", parseInt(id)+1)

}

function introLoader() {
  if(location.pathname === '/') {
    return <div id="overlay"><div className="overlayContainer"><div id="logo"><img src="logo.png" alt="The Idea Becomes a Machine" /><div className='loader'></div></div></div></div>
  } else {
    return <div id="overlay"><div className="overlayContainer"><div id="logo"><div className='loader singleLoaderHome'></div></div></div></div>
  }
}

const Start = () => (
  <>

  <div id="index"><a onClick={() => {state.clicked = null; closeSingle()}}>index</a></div>

  <Suspense fallback={introLoader()}>
    <div id="canvasContainer">
    <Canvas id="canvas" gl={{ antialias: false }} dpr={[1, 1.5]} onPointerMissed={() => {state.clicked = null; closeSingle()}}>
        <Items />
        <Environment preset="city" />
    </Canvas>
    </div>
  </Suspense>

  <Images/>

  <NextButton/>

  </>
)


export default Start;
