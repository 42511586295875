import React from "react";
import { AnimatePresence } from "framer-motion";
import { Routes, Route, Router, Link } from "react-router-dom";
import Start from "./start.js";
import Contact from "./contact.js";
import Leakage from "./leakage.js";
import Arrival from "./arrival.js";
import Collapse from "./collapse.js";
import Establishment from "./establishment.js";
import Conflict from "./conflict.js";
import Apocalypse from "./apocalypse.js";
import Triumph from "./triumph.js";
import Coda from "./coda.js";

import './styles.css'
import "./assets/scss/App.scss";

import gsap, { Power3 } from 'gsap'

function toggleMenu() {

  if(!document.getElementById("menuButton").classList.contains("menu-button--open")) {
    document.getElementById("menuButton").classList.add("menu-button--open");
    document.getElementById("nav").style.display = 'block'
    var menu = new gsap.timeline({paused:true});
    menu.add('start')
    menu.add('end')

    .to(document.getElementById("nav"), { duration: 0, ease: "expo", zIndex: 999999999 }, 'start')
    .to(document.getElementById("topPanel"), { duration: .5, ease: "expo", height: '50vh' }, 'start')
    .to(document.getElementById("bottomPanel"), { duration: .5, ease: "expo", height: '50vh' }, 'start')
    .to(document.getElementById("nav"), { duration: .2, ease: "expo", top: '0' }, 'end')
    .to(document.getElementById("nav"), { duration: 1, ease: "expo", opacity: '1' }, 'end')
    menu.play()
  } else {
    document.getElementById("menuButton").classList.remove("menu-button--open");
    document.getElementById("nav").style.display = 'none'

    var menu = new gsap.timeline({paused:true});
    menu.add('start')
    menu.add('end')
    .to(document.getElementById("nav"), { duration: 0, ease: "expo", zIndex: 0 }, 'start')
    .to(document.getElementById("nav"), { duration: .2, ease: "expo", y: '0' }, 'start')
    .to(document.getElementById("nav"), { duration: .5, ease: "expo", opacity: '0' }, 'start')

    .to(document.getElementById("topPanel"), { duration: 1, ease: "expo", height: '0' }, 'end')
    .to(document.getElementById("bottomPanel"), { duration: 1, ease: "expo", height: '0' }, 'end')

    menu.play()
  }


}

function App() {

  let pagination
  let nextSet, nextSetUrl, start, end

  if(location.pathname == '/') {
    nextSetUrl = '/contact'
    nextSet = 'chapter 2: <span>contact</span>'
  }

  if(location.pathname == '/contact') {
    nextSetUrl = '/leakage'
    nextSet = 'chapter 3: <span>leakage</span>'
  }

  if(location.pathname == '/leakage') {
    nextSetUrl = '/arrival'
    nextSet = 'chapter 4: <span>arrival</span>'
  }

  if(location.pathname == '/arrival') {
    nextSetUrl = '/collapse'
    nextSet = 'chapter 5: <span>collapse</span>'
  }

  if(location.pathname == '/collapse') {
    nextSetUrl = '/establishment'
    nextSet = 'chapter 6: <span>establishment</span>'
  }

  if(location.pathname == '/establishment') {
    nextSetUrl = '/conflict'
    nextSet = 'chapter 7: <span>conflict</span>'
  }

  if(location.pathname == '/conflict') {
    nextSetUrl = '/apocalypse'
    nextSet = 'chapter 8: <span>apocalypse</span>'
  }

  if(location.pathname == '/apocalypse') {
    nextSetUrl = '/triumph'
    nextSet = 'chapter 9: <span>triumph</span>'
  }

  if(location.pathname == '/triumph') {
    nextSetUrl = '/coda'
    nextSet = 'chapter 10: <span>coda</span>'
  }

  if(location.pathname == '/coda') {
    nextSetUrl = '/'
    nextSet = 'chapter 1: <span>start</span>'
  }

  return (

    <div className="App">
    <div id="galleryOverlay"></div>
    {
      // <div id="pagination" className="mobile">{nextSet}</div>
    }
    <div id="os"></div>
    <div id="leftPanel"></div>
    <div id="rightPanel"></div>
    <div id="topPanel"></div>
    <div id="bottomPanel"></div>

    <nav id="nav">
    <div className="navContainer">
        <ul>
        <li><a href="/">start</a></li>
        <li><a href="/contact">contact</a></li>
        <li><a href="/leakage">leakage</a></li>
        <li><a href="/arrival">arrival</a></li>
        <li><a href="/collapse">collapse</a></li>
        <li><a href="/establishment">establishment</a></li>
        <li><a href="/conflict">conflict</a></li>
        <li><a href="/apocalypse">apocalypse</a></li>
        <li><a href="/triumph">triumph</a></li>
        <li><a href="/coda">coda</a></li>
      </ul>
      </div>
    </nav>

    <div id="prompt"></div>

    <div id="tkn">Idea Machine <span id="tknID"></span>/512</div>

    <div id="singleLoader"></div>

    <AnimatePresence exitBeforeEnter>
      <Routes>
        <Route path="/" element={<Start/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/leakage" element={<Leakage/>} />
        <Route path="/arrival" element={<Arrival/>} />
        <Route path="/collapse" element={<Collapse/>} />
        <Route path="/establishment" element={<Establishment/>} />
        <Route path="/conflict" element={<Conflict/>} />
        <Route path="/apocalypse" element={<Apocalypse/>} />
        <Route path="/triumph" element={<Triumph/>} />
        <Route path="/coda" element={<Coda/>} />
        </Routes>
    </AnimatePresence>

    <button id="menuButton" className="menu-button" onClick={toggleMenu}><span>Menu</span></button>

    </div>
  )
}

export default App;
